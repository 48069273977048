import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { CustomErrorStateMatcher } from "@core/CustomErrorStateMatcher";
import { IEmployee } from "@shared/models/Employee";
import { AppService } from "@shared/services/app.service";
import { EmployeeService } from "@shared/services/employee.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

export interface IValue { // TODO sub with IEmployee?
  id: string;
  name: string;
  surname?: string;
}

/**
 * Form field employee component in angular material design
 */
@Component({
  selector: "app-employee-input",
  templateUrl: "./employee-input.component.html",
  styleUrls: ["./employee-input.component.scss"],
  host: { class: "mat-form-field" },
})
export class EmployeeInputComponent implements OnInit {
  constructor(
    private app: AppService,
    private service: EmployeeService,
    private rootFormGroup: FormGroupDirective
  ) {}

  private _value: IValue;
  @Input() set value(value: IValue) {
    if (!this.control) {
      this.pendingValue = value;
      return;
    }

    this.setValue(value);
  }
  get value() {
    return this._value;
  }

  @Input() label: string;
  @Input() name: string;
  @Input() surname: string;
  @Input() required: boolean;
  @Input() searchFn: (query: string) => Observable<IEmployee[]>;

  @Output() changed: EventEmitter<IEmployee> = new EventEmitter<IEmployee>();

  form: FormGroup;
  options: IEmployee[] = [];
  searchControlName: string;

  readonly employeeErrorStateMatcher = new CustomErrorStateMatcher(
    () => !this.control.valid,
    true
  );

  get control(): FormControl {
    return this.form && (this.form.get(this.name) as FormControl);
  }

  get resolvedId(): string {
    return (this.selectedOption && this.selectedOption.id) || this.id;
  }

  private readonly searchControl = new FormControl();
  private readonly searchTerms = new Subject<string>();

  private id: string;
  private selectedOption: IEmployee;
  private isDirty: boolean;
  private pendingValue: IValue;

  ngOnInit() {
    this.form = this.rootFormGroup.form;

    this.searchControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val) => {
        // check if it's a string to prevent search on autocomplete item selection
        typeof val == "string" && this.isDirty && this.search(val);
      });

    this.searchControlName = `${this.name}_search`;

    if (this.control.disabled) {
      this.searchControl.disable();
    }

    this.form.addControl(this.searchControlName, this.searchControl);

    if (this.pendingValue) {
      this.setValue(this.pendingValue);
      this.pendingValue = undefined;
    }

    this.control.registerOnDisabledChange((disabled) => {
      if (disabled) {
        this.searchControl.disable();
      } else {
        this.searchControl.enable();
      }
    });
  }

  /**
   * On employee selection from autocomplete
   * @param event Angular materila autocomplete selected event
   */
  onSelect(event: MatAutocompleteSelectedEvent) {
    const item = event.option.value as IEmployee;

    this.selectedOption = item;
    this.control.setValue(item);
    this.changed.emit(item);
  }

  /**
   * Open additional browser tab with employees profile
   */
  openProfile() {
    const tab = window.open('', '_blank');

    this.service.getProfileUrl(this.resolvedId).subscribe(url => {
      if (url) {
        tab.location.href = url;
      } else {
        tab.close();
        this.app.alerts.error(this.app.translate('error.employeeProfileUrlEmpty'));
      }
    });
  }

  /**
   * On search value change
   */
  onSearch() {
    const value = this.searchControl.value;

    this.isDirty = true;
    this.selectedOption = undefined;
    this.control.setValue(null);

    this.changed.emit(undefined);
    this.searchTerms.next(value);
  }

  /**
   * Get employee display string
   * @param item Employee
   */
  displayFn(item: IEmployee): string {
    return item ? `${item.name || ""} ${item.surname || ""}`.trim() : "";
  }

  private search(value: string) {
    const request = this.searchFn ? this.searchFn(value) : this.service.search(value);
    const loading = this.app.showLoading();

    request.subscribe((data) => {
      this.app.hideLoading(loading);
      this.options = data;
    });
  }

  private setValue(value: IValue) {
    if (value) {
      //const ns = (value.name || "").split(" ");

      const model = <IEmployee>{
        id: value.id,
        //name: ns[0],
        //surname: ns.splice(1).join(" ").trim(),
        name: value.name,
        surname: value.surname
      };

      this.control.setValue(model);
      this.searchControl.setValue(model);
    } else {
      value = {
        id: null,
        name: null,
        surname: null
      };

      this.control.setValue(null);
      this.searchControl.setValue(null);
    }

    this.id = value.id;
  }
}
