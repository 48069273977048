import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-length',
  templateUrl: './input-length.component.html',
  styleUrls: ['./input-length.component.scss']
})
export class InputMaxLengthComponent {
  @Input() control: AbstractControl;
  @Input() max: number;
}
