import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject, BehaviorSubject, Observable } from "rxjs";

import { AlertService } from "@shared/services/alert.service";
import { AuthService } from "@shared/services/auth.service";

import { User } from "@shared/models/Identity";
import { FontSize } from "@core/FontSize";
import { IBreadcrumb } from "@core/Breadcrumb";

/**
 * Common application service
 */
@Injectable({ providedIn: "root" })
export class AppService {
  constructor(
    public alerts: AlertService,
    public translator: TranslateService,
    private auth: AuthService
  ) { }

  readonly defaultLanguage: string = "lv";

  get isAuthenticated(): boolean {
    return this.auth.isAuthenticated;
  }

  get currentUser(): User {
    return this.auth.currentUser;
  }

  get currentLanguage(): string {
    return this.translator.currentLang;
  }

  get currentRoute(): string {
    return this.routeHistory[1];
  }

  get previousRoute(): string {
    return this.routeHistory[0];
  }

  get beforeCheckFormValidity(): Observable<FormGroup> {
    return this.checkFormValiditySubject.asObservable();
  }

  private loadings: { id: number; active: boolean }[] = [];

  private fontSizeSubject = new BehaviorSubject<FontSize>(
    <FontSize>localStorage.getItem("fontSize")
  );
  private documentClickSubject = new Subject<any>();
  private afterLoginSubject = new Subject<void>();
  private titleSubject = new Subject<string>();
  private breadcrumbSubject = new Subject<IBreadcrumb[]>();
  private checkFormValiditySubject = new Subject<FormGroup>();

  private routeHistory: string[] = [undefined, undefined];

  /**
   * Show loading animation.
   * @param delay
   * @returns Id of the loading animation
   */
  showLoading(delay: number = 100): number {
    const data = {
      id: Date.now(),
      active: undefined,
    };

    this.loadings.push(data);

    setTimeout(() => {
      if (data.active !== false) {
        data.active = true;
      }
    }, delay);

    return data.id;
  }

  /**
   * Hide loading animation.
   * @param id Id of the loading animation. If omitted, then hide all loading animations.
   * @param delay
   */
  hideLoading(id?: number, delay: number = 400) {
    setTimeout(() => {
      if (id) {
        const i = this.loadings.findIndex((t) => t.id === id);

        if (i > -1) {
          this.loadings[i].active = false;
          this.loadings.splice(i, 1);
        }
      } else {
        this.loadings.forEach((t) => this.hideLoading(t.id, delay));
      }
    }, delay);
  }

  /**
   * Remove all loading animations.
   */
  clearLoading() {
    this.loadings = [];
  }

  /**
   * Check if there is an active loading animation.
   */
  isLoading(): boolean {
    return this.loadings.some((t) => t.active);
  }

  /**
   * Get instant translation by key.
   * @param key
   */
  translate(key: string): string {
    if (!key) {
      return "";
    }

    let value = this.translator.instant(key);

    if (value instanceof Array) {
      value = value.join("");
    }

    return value;
  }

  /**
   * Translate item property using i18n translation.
   * @param item Item
   * @param property Item property string name
   * @returns
   */
  translateProperty(item: any, property: string): any {
    if (!item) return "";

    const langProp = `${property}${this.currentLanguage}`.toLowerCase();
    let result = item[property] || item[property.toLowerCase()];

    Object.keys(item).forEach((t) => {
      if (t.toLowerCase() === langProp) {
        result = item[t];
      }
    });

    return result;
  }

  /**
   * User logged in
   * Sets next event on after login subject
   */
  loggedIn() {
    this.afterLoginSubject.next();
  }

  /**
   * After user logged in
   * @returns After login subject as observable
   */
  afterLogin(): Observable<void> {
    return this.afterLoginSubject.asObservable();
  }

  /**
   * Set font size
   * @param size Font size enum
   */
  setFontSize(size: FontSize) {
    localStorage.setItem("fontSize", size);
    this.fontSizeSubject.next(size);
  }

  /**
   * Get font change observable
   */
  getFontSize(): Observable<FontSize> {
    return this.fontSizeSubject.asObservable();
  }

  /**
   * Set current page title.
   * @param title
   */
  setTitle(title: string) {
    this.titleSubject.next(title);
  }

  /**
   * Get current page title.
   */
  getTitle(): Observable<string> {
    return this.titleSubject.asObservable();
  }

  /**
   * Html document mouse click event
   * @param event Mouse click event
   */
  documentClick(event) {
    this.documentClickSubject.next(event);
  }

  /**
   * Html document mouse click event observable
   */
  onDocumentClick() {
    return this.documentClickSubject.asObservable();
  }

  /**
   * Get http error as translation using i18n
   * @param error Http error
   */
  getHttpError(error: any): string {
    if (!error) {
      return this.translate("error.unknown");
    }

    let text = "";
    let details: string[] = [];

    const requiredFieldErr = (text: string) => {
      const reqFieldErr = text.split("Required");

      if (reqFieldErr.length === 2) {
        const reqField = this.translate(reqFieldErr[0]);
        return this.translate("error.fieldRequired").replace("{0}", reqField);
      }

      return "";
    };

    if (error instanceof HttpErrorResponse) {
      if (error.error) {
        const httpErr = error.error;

        if (typeof httpErr === "string") {
          text = httpErr;
        } else {
          text = httpErr.message || httpErr.title;
          details = httpErr.details;
        }
      }

      if (text) {
        let initialText = text;
        text = this.translate(text);

        if (!text || text === initialText) {
          text = initialText = `error.${text}`;
          text = this.translate(text);

          if (!text || text === initialText) {
            text = requiredFieldErr(text);
          }
        }
      } else if (error.status) {
        let statusKey: string;

        switch (error.status) {
          case 403:
            statusKey = "accessDenied";
            break;
          case 404:
            statusKey = "notFound";
            break;
          default:
            statusKey = error.status.toString();
        }

        const errorCodeKey = `error.${statusKey}`;
        const httpErrorText = this.translate(errorCodeKey);

        if (httpErrorText && httpErrorText !== errorCodeKey) {
          text = httpErrorText;
        }
      }
    }

    if (!text) {
      text = this.translate(error.message);

      if (!text || text === error.message) {
        text = this.translate("error.unknown");
      }
    }

    if (details && details.length) {
      details.forEach((t) => {
        if (t && typeof t === "string") {
          text += `<br>${requiredFieldErr(t) || this.translate(t)}`;
        }
      });
    }

    return text;
  }

  /**
   * Register angular router change to route history
   * @param route
   */
  addRouteHistory(route: string) {
    this.routeHistory[0] = this.routeHistory[1];
    this.routeHistory[1] = route;
  }

  /**
   * Get user selected language. If not set, default language is returned.
   **/
  getUserLanguage(): string {
    return localStorage.getItem("language") || this.defaultLanguage;
  }

  /**
   * Change current language
   * @param language Language code - lv, en
   */
  switchLanguage(language: string) {
    if (language) {
      localStorage.setItem("language", language);
      location.reload();
    }
  }

  /**
   * Check form validity and show an alert, if the form is not valid.
   * @param form FormGroup
   */
  checkFormValidity(form: FormGroup): boolean {
    this.checkFormValiditySubject.next(form);

    const required: string[] = [];
    const other: { label: string, error: string }[] = [];

    for (const c in form.controls) {
      const control = form.controls[c];

      control.markAsDirty();

      if (control.errors) {
        const input = document.querySelector(`.mat-form-field [formcontrolname="${c}"]`);

        if (input) {
          const field = input.closest('.mat-form-field');

          if (field) {
            const label = field.querySelector('mat-label')?.textContent?.trim() || c;
            const error = field.querySelector('mat-error')?.textContent?.trim();

            if (control.errors.required) {
              required.push(label);
            } else {
              other.push({
                label,
                error
              });
            }
          } else {
            required.push(c); // fallback
          }
        }
      }
    }

    if (!form.valid) {
      let message = '';

      if (required.length) {
        message += `<p>${this.translate('error.requiredFieldsPretty')}</p>`;
        message += `<ul>${required.map(t => `<li>${t}</li>`).join('')}</ul>`;
      }

      if (other.length) {
        message += `<p>${this.translate('error.invalidFields')}</p>`;
        message += `<ul>${other.map(t => `<li><div>${t.label}</div><div class="error">${t.error}</div></li>`).join('')}</ul>`;
      }

      this.alerts.error(`<div class="form-errors-alert">${message}</div>`);
      return false;
    }

    return true;
  }

  /**
   * Confirmation to proceed when user might lose data
   */
  confirmDataLoss(): Observable<boolean> {
    return this.alerts.confirm({
      text: this.translate("common.confirmUnsavedDataLoss"),
      ok: this.translate("common.yes"),
    });
  }

  /**
   * Get breadcrumb array subject as observable
   */
  getBreadcrumbs(): Observable<IBreadcrumb[]> {
    return this.breadcrumbSubject.asObservable();
  }

  /**
   * Set subjects next breadcrumb array
   * @param items Breadcrumb array
   */
  setBreadcrumbs(items: IBreadcrumb[]) {
    this.breadcrumbSubject.next(items);
  }
}
