import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import {
  ITextTemplate,
  ITextTemplateEditModel,
} from "@shared/models/TextTemplate";

import { environment as env } from "src/environments/environment";

/**
 * Text template service
 */
@Injectable({ providedIn: "root" })
export class TextTemplateService {
  constructor(public http: HttpClient) {}

  public get apiUrl(): string {
    return `${env.apiUrl}/textTemplates`;
  }

  /**
   * Get text templates
   * @param code Optional: Text template code
   * @returns Http request observable returns text template array
   */
  get(code?: string): Observable<ITextTemplate[]> {
    const params: any = {};

    if (code) {
      params.code = code;
    }

    return this.http.get<ITextTemplate[]>(this.apiUrl, { params });
  }

  /**
   * Get text template
   * @param code Text template code
   * @returns Http request observable returns text template
   */
  getByCode(code: string): Observable<ITextTemplate> {
    const params: any = {};

    if (code) {
      params.code = code;
    }

    return this.http
      .get<ITextTemplate>(this.apiUrl, {
        params: params,
      })
      .pipe(map((v) => v[0]));
  }

  /**
   * Create text template
   * @param item Text template edit model
   * @returns Http request observable returns text template id
   */
  create(item: ITextTemplateEditModel): Observable<number> {
    return this.http.post<number>(this.apiUrl, item);
  }

  /**
   * Update text template
   * @param id Text template id
   * @param item Text template edit model
   * @returns Http request observable
   */
  update(id: number, item: ITextTemplateEditModel): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.put<any>(url, item);
  }

  /**
   * Delete text template
   * @param id Texte template id
   * @returns Http request observable
   */
  delete(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<any>(url);
  }

  /**
   * Find text template text
   * @param items Text template array
   * @param code Text template code
   * @returns Text template string text
   */
  findText(items: ITextTemplate[], code: string): string {
    const item = items.find((t) => t.code === code);
    return item ? item.content : null;
  }
}
