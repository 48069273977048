<footer class="site-footer">
  <div class="container">
    <div class="content">
      <div class="logo-container">
        <a [routerLink]="['/']" class="logo">
          <img src="{{ 'nav.logoUrl' | translate }}" alt="logo" />
        </a>
      </div>
      <div class="info">
        <img src="../../../../assets/img/footer-info.png" alt="info" />
      </div>
    </div>
    <div class="footnote">
      Aktivitāte tiek realizēta ESF līdzfinansēta projekta “Pārvaldības procesu
      pilnveide un studiju programmu satura modernizācija Rīgas Stradiņa
      universitātē” (Nr.: 8.2.3.0/18/A/011) ietvaros
    </div>
    <div class="copyright">
      {{ "common.copyright" | translate | formatString: year }},&nbsp;
      <a href="tel:+37167409261">+371 67409261</a>,&nbsp;
      <a href="mailto:rsu@rsu.lv">programmas@rsu.lv</a>
    </div>
  </div>
</footer>
