import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { IDepartment, IEmployee } from "@shared/models/Employee";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  constructor(private http: HttpClient) {}

  get apiUrl(): string {
    return `${environment.apiUrl}/employees`;
  }

  private readonly cache = {
    profileUrls: {},
    departmentUrls: {},
  };

  /**
   * Get departments
   * @returns Http request observable returns department array
   */
  getDepartments(): Observable<IDepartment[]> {
    return this.http.get<IDepartment[]>(`${this.apiUrl}/departments`);
  }

  /**
   * Search for employee
   * @param searchInput Search string
   * @returns Http request observable returns emplyee array
   */
  search(searchInput: string): Observable<IEmployee[]> {
    return this.http.get<IEmployee[]>(`${this.apiUrl}/search`, {
      params: {
        value: searchInput
      },
    });
  }

  /**
   * Get employees profile url
   * @param id Employee id
   * @returns Http request observable returns emplyees profile url
   */
  getProfileUrl(id: string): Observable<string> {
    if (this.cache.profileUrls[id]) {
      return of(this.cache.profileUrls[id]);
    }

    return this.http
      .get(`${this.apiUrl}/${id}/url`, { responseType: "text" })
      .pipe(
        tap((data) => {
          this.cache.profileUrls[id] = data;
        })
      );
  }

  /**
   * Get department url
   * @param id Department id
   * @returns Http request observable returns department url
   */
  getDepartmentUrl(id: string): Observable<string> {
    if (this.cache.departmentUrls[id]) {
      return of(this.cache.departmentUrls[id]);
    }

    return this.http
      .get(`${this.apiUrl}/departments/${id}/url`, { responseType: "text" })
      .pipe(
        tap((data) => {
          this.cache.departmentUrls[id] = data;
        })
      );
  }
}
