<div class="box" *ngIf="hasItems">
  <div class="alert alert-warning">
    {{'studyPlanAlerts.warning' | translate}}
  </div>

  <div class="table-wrapper">
    <table mat-table [dataSource]="table">
      <ng-container matColumnDef="studyProgramme">
        <th mat-header-cell *matHeaderCellDef>{{'studyPlanAlerts.studyProgramme' | translate}}</th>
        <td mat-cell *matCellDef="let n">
          {{n.studyProgramme | translateProp:'name'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="implementedProgramme">
        <th mat-header-cell *matHeaderCellDef>{{'studyPlanAlerts.implementedProgramme' | translate}}</th>
        <td mat-cell *matCellDef="let n">
          {{n.implementedProgramme | translateProp:'name'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef>{{'studyPlanAlerts.period' | translate}}</th>
        <td mat-cell *matCellDef="let n">
          <span [matTooltip]="getAffectedPeriodsDisplay(n)">
            {{n.period | translateProp:'name'}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef>{{'studyPlanAlerts.version' | translate}}</th>
        <td mat-cell *matCellDef="let n">
          <a [routerLink]="['/implemented-programmes/details', n.implementedProgramme.id]" [queryParams]="{
             tab: 'courses',
             planId: n.id,
             versionId: n.versionId
             }">{{n.version}}</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToShow;"></tr>
    </table>
  </div>
</div>
