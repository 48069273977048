export interface ITree<TNode extends ITreeNode> {
  root: TNode;
}

export interface ITreeNode {
  parent?: ITreeNode;
  children: ITreeNode[];
  index: number;
}

export class Tree<TNode extends ITreeNode> {
  private _nodes: TNode[] = [];
  get nodes(): TNode[] {
    return this._nodes;
  }
  private set nodes(value: TNode[]) {
    this._nodes = value;
  }

  get roots(): TNode[] {
    return this.nodes.filter(t => !t.parent);
  }

  getAncestors(node: TNode): TNode[] {
    const items: TNode[] = [];

    while (node?.parent) {
      items.push(node.parent as TNode);
      node = node.parent as TNode;
    }

    return items;
  }

  getDescendants(node: TNode): TNode[] {
    const items: TNode[] = [...node.children as TNode[]];

    node.children.forEach(t => {
      const sub = this.getDescendants(t as TNode);
      items.push(...sub);
    });

    return items;
  }

  /**
   * Populate the tree from a data array.
   * @param data Data items
   * @param nodeFn A function to build a tree node
   * @param childFn A function to check parent-child relationship
   */
  build<T>(
    data: T[],
    nodeFn: (data: T) => TNode,
    childFn: (item: TNode, parent: TNode) => boolean
  ) {
    if (!data.length) {
      this.nodes = [];
      return;
    }

    const nodes = data.map(t => {
      return nodeFn(t);
    });

    nodes.forEach(t => {
      t.children = nodes.filter(n => childFn(n, t)).map((n, j) => {
        n.index = j;
        n.parent = t;
        return n;
      });
    });

    const roots = nodes.filter(t => !t.parent);

    roots.forEach((t, i) => {
      t.index = i;
    });

    this.nodes = nodes;
  }
}
