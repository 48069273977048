import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { IParameter } from "@shared/models/Parameter";
import { AuthService } from "@shared/services/auth.service";

import { environment as env } from "src/environments/environment";

const cache: {
  items?: IParameter[];
} = {};

/**
 * Parameter service
 */
@Injectable({ providedIn: "root" })
export class ParameterService {
  constructor(public http: HttpClient, auth: AuthService) {
    auth.getUser().subscribe(() => this.clearCache());
  }

  public get apiUrl(): string {
    return `${env.apiUrl}/parameters`;
  }

  /**
   * Get parameter array
   * @returns Http request observable returns parameter array
   */
  get(): Observable<IParameter[]> {
    if (cache.items) {
      return of(cache.items);
    }

    return this.http
      .get<IParameter[]>(this.apiUrl)
      .pipe(tap((data) => (cache.items = data)));
  }

  /**
   * Update parameter
   * @param id Parameter id
   * @param value Parameter value
   * @returns Http request obaservable
   */
  update(id: number, value: string): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http
      .put(url, {
        Value: value,
      })
      .pipe(tap(() => this.clearCache()));
  }

  /**
   * Get parameter value by code
   * @param code Parameter code
   * @param items Parameter array
   * @returns Parameter value
   */
  findValue(code: string, items: IParameter[]): string {
    const item = (items || []).find((t) => t.code === code);
    return item ? item.value : null;
  }

  private clearCache() {
    cache.items = undefined;
  }
}
