import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContactsComponent } from './contacts/contacts.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMenuComponent } from './header/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { MainPageRoutingModule } from './main-routing.module';
import { StudyPlanAlertsComponent } from './study-plan-alerts/study-plan-alerts.component';

@NgModule({
  imports: [
    MainPageRoutingModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    HeaderMenuComponent,
    HomeComponent,
    FooterComponent,
    ContactsComponent,
    StudyPlanAlertsComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class MainPageModule { }
