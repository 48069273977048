import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `
    <span class="spinner">
      <mat-spinner [diameter]="16" [color]="color"></mat-spinner>
    </span>`
})
export class SpinnerComponent {
  constructor() { }

  @Input() color: any;
}
