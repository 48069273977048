import { AuthType } from "./Auth";

export class User {
  id: string;
  userName: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  roles: string[] = [];
  permissions: string[] = [];
  authType: AuthType;
  employeeId?: string;

  get isAdmin(): boolean {
    return this.roles.includes(roles.admin);
  }

  get isAuthenticated(): boolean {
    return !!this.id;
  }

  get displayName(): string {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].filter((t) => !!t).join(' ');
    }

    return this.userName;
  }

  //get canDelegateOwnRights(): boolean {
  //  return !!this.employeeId;
  //}

  hasRole(role: string): boolean {
    return this.isAdmin || this.roles.includes(role);
  }

  hasPermission(permission: string): boolean {
    return this.isAdmin || this.permissions.indexOf(permission) !== -1;
  }
}

export interface IUser {
  id: string;
  userName: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  isDisabled: boolean;
  isAdmin?: boolean;
  roles: IUserRole[];
  logins: IUserLogin[];
}

export interface IUserLogin {
  id: string;
  authType: AuthType;
  userName: string;
}

export interface IRole {
  id: string;
  name: string;
  adfsName?: string;
  readOnly: boolean;
  permissions: string[];
}

export interface IUserRole {
  id: string;
  name: string;
}

export const roles = {
  admin: 'Admin'
};

export const userNames = {
  admin: 'admin'
};

export const permissions = {
  // admin
  classifierView: 'classifier.view',
  classifierEdit: 'classifier.edit',
  documentTemplateView: 'document_template.view',
  documentTemplateEdit: 'document_template.edit',
  parameterView: 'parameter.view',
  parameterEdit: 'parameter.edit',
  textTemplateView: 'text_template.view',
  textTemplateEdit: 'text_template.edit',
  userView: 'user.view',
  userEdit: 'user.edit',
  roleView: 'role.view',
  roleEdit: 'role.edit',
  logView: 'log.view',
  rightsDelegationAll: 'rights_delegation.all',
  // business
  studyDirectionView: 'study_direction.view',
  studyDirectionViewActual: 'study_direction.view_actual',
  studyDirectionEdit: 'study_direction.edit',
  studyProgrammeView: 'study_programme.view',
  studyProgrammeEdit: 'study_programme.edit',
  studyProgrammeDelete: 'study_programme.delete',
  implementedProgrammeView: 'implemented_programme.view',
  implementedProgrammeEdit: 'implemented_programme.edit',
  implementedProgrammeDelete: 'implemented_programme.delete',
  studyPlanView: 'study_plan.view',
  studyPlanEdit: 'study_plan.edit',
  studyPlanEditPast: 'study_plan.edit_past',
  studyPlanCompare: 'study_plan.compare',
  studyPlanApproval: 'study_plan.approval',
  studyPlanFinalize: 'study_plan.finalize',
  requirementDocumentView: 'requirement_document.view',
  requirementDocumentEdit: 'requirement_document.edit'
};
