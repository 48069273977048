import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { ITreeNode, Tree } from '@core/Tree';

@Component({
  selector: 'app-tree',
  template: `<app-tree-node *ngFor="let node of tree.roots" [node]="node" [template]="templateRef"></app-tree-node>`
})
export class TreeComponent<T extends ITreeNode> {
  @Input() tree: Tree<T>;
  @ContentChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;
}

@Component({
  selector: 'app-tree-node',
  template: `<ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{$implicit: node}"></ng-container>`
})
export class TreeNodeComponent {
  @Input() node: ITreeNode;
  @Input() template: TemplateRef<any>;
}
