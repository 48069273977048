<div class="top">
  <div class="container">
    <div class="top-wrapper">
      <nav class="top-primary">
        <span class="app-version">v{{appVersion}}</span>
        <ul class="nav-list">
          <li *ngFor="let n of getVisible(topLinks)">
            <a class="nav-link" [routerLink]="[n.url]" [routerLinkActive]="['current']" *ngIf="!n.externalUrl">
              <span>{{'nav.' + n.name | translate}}</span>
            </a>
            <a class="nav-link" href="{{n.externalUrl}}" target="_blank" *ngIf="n.externalUrl">
              <span>{{'nav.' + n.name | translate}}</span>
            </a>
          </li>
        </ul>
      </nav>

      <nav class="top-secondary">
        <ul class="nav-list">
          <li class="language-switch">
            <span class="nav-item nowrap">
              <a href="#" *ngFor="let n of languages" class="language language-{{n.code}}"
                 [class.current]="language === n.code"
                 (click)="$event.preventDefault(); switchLanguage(n);">{{n.label}}</a>
            </span>
          </li>

          <li *ngIf="isAuthenticated && !adminMenu.isEmpty">
            <a href="#" (click)="$event.preventDefault(); toggleMenu(adminMenu);" class="nav-item sub-nav-toggle"
               [class.current]="adminMenu.isOpened">
              <mat-icon>settings</mat-icon>
              <span>{{'nav.admin' | translate}}</span>
            </a>
            <app-header-menu *ngIf="adminMenu.isOpened" [items]="adminMenu.items" [style]="'align-right'"></app-header-menu>
          </li>

          <li *ngIf="!isAuthenticated">
            <a [routerLink]="['/auth/login']" class="nav-link">
              <mat-icon>login</mat-icon>
              <span>{{'nav.login' | translate}}</span>
            </a>
          </li>

          <li *ngIf="isAuthenticated" id="nav-item-user">
            <a href="#" (click)="$event.preventDefault(); toggleMenu(userMenu);" class="nav-item sub-nav-toggle"
               [class.current]="userMenu.isOpened">
              <mat-icon>person</mat-icon>
              <span class="user">
                <span class="user-name">{{userName}}</span>
                <span class="user-role" *ngIf="userRoles.length > 1" [title]="userRoles | arrayJoin:', '">{{'nav.accountManyRoles' | translate}}</span>
                <span class="user-role" *ngIf="userRoles.length == 1">{{userRoles[0]}}</span>
              </span>
            </a>
            <app-header-menu *ngIf="userMenu.isOpened" [items]="userMenu.items" [style]="'align-right'"></app-header-menu>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<div class="main" [class.main-opened]="mainNavOpened">
  <div class="container">
    <div class="main-wrapper">
      <div class="branding">
        <a [routerLink]="['/']" class="logo">
          <img src="{{'nav.logoUrl' | translate }}" alt="logo">
        </a>
      </div>

      <nav class="main-top" *ngIf="getVisible(topLinks).length">
        <ul class="nav-list">
          <li *ngFor="let n of getVisible(topLinks)">
            <a class="nav-link" [routerLink]="[n.url]" [routerLinkActive]="['current']" *ngIf="!n.externalUrl">
              <span>{{'nav.' + n.name | translate}}</span>
            </a>
            <a class="nav-link" href="{{n.externalUrl}}" target="_blank" *ngIf="n.externalUrl">
              <span>{{'nav.' + n.name | translate}}</span>
            </a>
          </li>
        </ul>
      </nav>

      <nav class="main-primary" *ngIf="getVisible(mainLinks).length">
        <ul class="nav-list">
          <li *ngFor="let n of getVisible(mainLinks)">
            <a class="nav-link" [routerLink]="[n.url]" [routerLinkActive]="['current']">
              <span [innerHTML]="'nav.' + n.name | translate" class="main-link-bl"></span>
              <span class="main-link-sl">{{toSingleLine('nav.' + n.name | translate)}}</span>
            </a>
          </li>
        </ul>
      </nav>

      <button type="button"
              class="main-toggle"
              (click)="toggleMainNav()"
              [class.top-hidden]="!getVisible(topLinks).length"
              [class.main-hidden]="!getVisible(mainLinks).length">
        <mat-icon>{{mainNavOpened ? 'clear' : 'menu'}}</mat-icon>
      </button>
    </div>
  </div>
</div>
