import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, BehaviorSubject, of, throwError } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";
import { User } from "@shared/models/Identity";
import {
  IAuthConfiguration,
  ILoginResponse,
  ILogoutResponse,
  IUserData,
} from "@shared/models/Auth";

import { environment as env } from "src/environments/environment";

const storageKeys = {
  user: "user",
  accessToken: "auth.token",
  accessTokenExpires: "auth.tokenExpires",
  getAuth: "auth.get",
  setAuth: "auth.set",
  endAuth: "auth.end",
};

export const anonymous = new User();

/**
 * Authentication service
 */
@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(private http: HttpClient) {
    this.storageKey = Date.now().toString();
    this.setupListeners();
  }

  get apiUrl(): string {
    return `${env.apiUrl}/auth`;
  }

  get refreshUrl(): string {
    return `${this.apiUrl}/refresh`;
  }

  get loginUrl(): string {
    return `${this.apiUrl}/login`;
  }

  get logoutUrl(): string {
    return `${this.apiUrl}/logout`;
  }

  private _currentUser: User;
  get currentUser(): User {
    return this._currentUser || anonymous;
  }

  private _accessToken: string;
  get accessToken(): string {
    return this._accessToken;
  }

  get isAuthenticated(): boolean {
    return this.currentUser.isAuthenticated;
  }

  private storage = sessionStorage;
  private storageKey: string;
  private accessTokenExpires: Date;

  private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(anonymous);

  /**
   * Get current user subject as observable
   */
  getUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  /**
   * Get current configoration requesting http get
   * @returns Http request observable
   */
  getConfiguration(): Observable<IAuthConfiguration> {
    const url = `${this.apiUrl}/configuration`;
    return this.http.get<IAuthConfiguration>(url);
  }

  /**
   * Login using code
   * @param code Login code
   * @returns Login response observable
   */
  loginByCode(code: string): Observable<ILoginResponse> {
    return this.http
      .post<ILoginResponse>(this.loginUrl, { code }, { withCredentials: true })
      .pipe(
        tap((data) => {
          this.parseLoginResponse(data);
          return data;
        })
      );
  }

  /**
   * Login using username and passowrd
   * @param username Username
   * @param password Password
   * @returns Login response observable
   */
  loginByUserNameAndPassword(
    username: string,
    password: string
  ): Observable<ILoginResponse> {
    return this.http
      .post<ILoginResponse>(
        this.loginUrl,
        { username, password },
        { withCredentials: true }
      )
      .pipe(
        tap((data) => {
          this.parseLoginResponse(data);
          return data;
        })
      );
  }

  /**
   * Logout
   * @returns Logout response observable
   */
  logout(): Observable<ILogoutResponse> {
    return this.http
      .post<ILogoutResponse>(this.logoutUrl, null, { withCredentials: true })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status == 401) {
            return of({});
          }

          return throwError(err);
        }),
        tap((data) => {
          this.clearSession(true);
          return data;
        })
      );
  }

  /**
   * Authentications process
   */
  authenticate(): Observable<User> {
    if (this.isAuthenticated) return of(this.currentUser);

    // get from the current storage
    try {
      const user = this.createUser(
        <IUserData>JSON.parse(this.storage.getItem(storageKeys.user))
      );

      if (user.id) {
        this.setUser(user, {
          token: this.storage.getItem(storageKeys.accessToken),
          expires: new Date(
            +this.storage.getItem(storageKeys.accessTokenExpires)
          ),
        });
      }
    } catch (err) {
      this.clearSession();
    }

    if (this.isAuthenticated) {
      return of(this.currentUser);
    }

    // get from the other tab
    return this.requestFromOtherSession();
  }

  /**
   * Refresh user authentication
   * @param emit Emit refreshed user data to the user observables.
   * @returns
   */
  refresh(emit: boolean = true): Observable<string> {
    return <Observable<string>>this.http.post<ILoginResponse>(this.refreshUrl, null, {
      withCredentials: true
    }).pipe(
      map((data) => {
        this.parseLoginResponse(data, emit);
        return data.accessToken;
      }),
      //catchError((err) => {
      //  // if something goes wrong, force client-side logout
      //  //this.clearSession();
      //  // fail silently
      //  return of(err);
      //})
    );
  }

  /**
   * Check if access token expired.
   */
  accessTokenExpired(): boolean {
    return (this.accessTokenExpires?.getTime() - new Date().getTime()) / 60000 < 1;
  }

  /**
   * Reset user password.
   * @param password Password
   * @param secret Secret
   */
  resetPassword(password: string, secret?: string): Observable<any> {
    const url = `${this.apiUrl}/password`;
    return this.http.post(url, { password, secret });
  }

  /**
   * Clear user session.
   * @param allSessions Clear all user sessions in the current browser
   */
  clearSession(allSessions: boolean = true) {
    this.setUser(anonymous, { token: undefined, expires: new Date() });

    if (allSessions) {
      this.dispatchEvent(storageKeys.endAuth, "1");
    }
  }

  private parseLoginResponse(data: ILoginResponse, emit: boolean = true) {
    const user = this.createUser(data);
    this.setUser(user, {
      token: data.accessToken,
      expires: new Date(data.accessTokenExpires),
    }, emit);
  }

  private createUser(data: IUserData): User {
    const user = new User();

    if (data) {
      user.userName = data.userName;
      user.email = data.email;
      user.firstName = data.firstName;
      user.lastName = data.lastName;
      user.id = data.id;
      user.roles = data.roles || [];
      user.permissions = data.permissions || [];
      user.authType = data.authType;
      user.employeeId = data.employeeId;
    }

    return user;
  }

  private setUser(user: User, accessToken: { token: string; expires: Date }, emit: boolean = true) {
    if (user) {
      this.storage.setItem(storageKeys.user, JSON.stringify(user));
      this.storage.setItem(storageKeys.accessToken, accessToken.token);
      this.storage.setItem(storageKeys.accessTokenExpires, accessToken.expires.getTime().toString());
    } else {
      this.storage.removeItem(storageKeys.user);
      this.storage.removeItem(storageKeys.accessToken);
      this.storage.removeItem(storageKeys.accessTokenExpires);
    }

    this._currentUser = user;
    this._accessToken = accessToken.token;

    this.accessTokenExpires = accessToken.expires;

    if (emit) {
      this.userSubject.next(user);
    }

    this.shareAuth();
  }

  private requestFromOtherSession(): Observable<User> {
    const subj = new Subject<User>();
    const getKey = `${storageKeys.getAuth}${this.storageKey}`;

    this.dispatchEvent(getKey, "1");

    setTimeout(() => {
      subj.next(this.isAuthenticated ? this.currentUser : anonymous);
    }, 100);

    return subj.asObservable();
  }

  private setupListeners() {
    window.addEventListener("storage", (event) => {
      if ((event.key || "").indexOf(storageKeys.getAuth) === 0) {
        // other tab requested an authentication transfer
        this.shareAuth();
      } else if (event.key === storageKeys.setAuth) {
        // current tab received an authentication transfer
        if (!this.isAuthenticated && event.newValue) {
          const data = JSON.parse(event.newValue);
          const user = this.createUser(<IUserData>JSON.parse(data.user));

          this.setUser(user, {
            token: data.token,
            expires: new Date(data.expires),
          });
        }
      } else if (event.key === storageKeys.endAuth) {
        this.clearSession(false);
      }
    });
  }

  private dispatchEvent(event: string, data: string) {
    localStorage.setItem(event, data);
    localStorage.removeItem(event);
  }

  /**
   * Share current authentication with other tabs
   */
  private shareAuth() {
    this.dispatchEvent(
      storageKeys.setAuth,
      JSON.stringify({
        user: this.storage.getItem(storageKeys.user),
        token: this.accessToken,
        expires: this.accessTokenExpires,
      })
    );
  }
}
