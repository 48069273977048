<ul *ngIf="getVisible(items).length" class="sub-nav {{style}}">
  <li *ngFor="let n of getVisible(items)">
    <a *ngIf="n.externalUrl" target="_blank" href="{{n.externalUrl}}">
      <mat-icon *ngIf="n.icon">{{n.icon}}</mat-icon>
      <span>
        {{n.text || ('nav.' + n.name | translate)}}
      </span>
    </a>
    <a *ngIf="!n.externalUrl" [routerLink]="[n.url]" [routerLinkActive]="['current']">
      <mat-icon *ngIf="n.icon">{{n.icon}}</mat-icon>
      <span>
        {{n.text || ('nav.' + n.name | translate)}}
      </span>
    </a>
  </li>
</ul>
