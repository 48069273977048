import { Component, OnInit } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { permissions, User } from "@shared/models/Identity";
import { AppService } from "@shared/services/app.service";
import { AuthService } from "@shared/services/auth.service";
import { RightsDelegationService } from "@shared/services/rights-delegation.service";
import { appVersion } from "src/version";

class Menu {
  constructor(items: INavLink[]) {
    this.items = items;
  }

  readonly items: INavLink[] = [];

  private opened?: boolean;

  get isOpened(): boolean {
    return this.opened;
  }

  get isEmpty(): boolean {
    return !this.items.some((t) => t.visible(t));
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
  }

  toggle() {
    this.opened = !this.opened;
  }
}

export interface INavLink {
  name: string;
  text?: string;
  url?: string;
  externalUrl?: string;
  queryParams?: any;
  icon?: string;
  visible?: (self: INavLink) => boolean;
}

interface ILanguage {
  code: string;
  label: string;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private app: AppService,
    private auth: AuthService,
    private rightsDelegation: RightsDelegationService,
    private router: Router
  ) {}

  readonly appVersion = appVersion;

  userName: string;
  userRoles: string[] = [];
  isAuthenticated: boolean;
  isAdmin: boolean;
  mainNavOpened: boolean;
  menus: Menu[] = [];

  readonly languages: ILanguage[] = [
    { code: "lv", label: "LV" },
    { code: "en", label: "EN" },
  ];

  readonly topLinks: INavLink[] = [
    { name: "info", url: "/" },
    { name: "contacts", url: "/contacts" },
    { name: "support", externalUrl: this.app.translate("nav.supportUrl") },
  ];

  readonly mainLinks: INavLink[] = [
    {
      name: "studyDirections",
      url: "/study-directions",
      visible: () =>
        this.user.hasPermission(permissions.studyDirectionView) ||
        this.user.hasPermission(permissions.studyDirectionViewActual),
    },
    {
      name: "studyProgrammes",
      url: "/study-programmes",
      visible: () => this.user.hasPermission(permissions.studyProgrammeView),
    },
    {
      name: "implementedProgrammes",
      url: "/implemented-programmes",
      visible: () =>
        this.user.hasPermission(permissions.implementedProgrammeView),
    },
    {
      name: "studyPlanCompare",
      url: "/study-plan-compare",
      visible: () => this.user.hasPermission(permissions.studyPlanCompare),
    },
    {
      name: "requirementDocuments",
      url: "/requirement-documents",
      visible: () => this.user.hasPermission(permissions.requirementDocumentView),
    }
  ];

  readonly adminMenu = new Menu([
    {
      name: "parameters",
      url: "admin/parameters",
      visible: () => this.user.hasPermission(permissions.parameterView),
    },
    {
      name: "classifiers",
      url: "admin/classifiers",
      visible: () => this.user.hasPermission(permissions.classifierView),
    },
    {
      name: "textTemplates",
      url: "admin/text-templates",
      visible: () => this.user.hasPermission(permissions.textTemplateView),
    },
    {
      name: "documentTemplates",
      url: "admin/document-templates",
      visible: () => this.user.hasPermission(permissions.documentTemplateView),
    },
    {
      name: "rightsDelegation",
      url: "rights-delegation/all",
      visible: () => this.user.hasPermission(permissions.rightsDelegationAll),
    },
    {
      name: "users",
      url: "admin/users",
      visible: () => this.user.hasPermission(permissions.userView),
    },
    {
      name: "roles",
      url: "admin/roles",
      visible: () => this.user.hasPermission(permissions.roleView),
    },
    {
      name: "log",
      url: "admin/log",
      visible: () => this.user.hasPermission(permissions.logView),
    },
  ]);

  readonly userMenu = new Menu([
    {
      name: "account",
      url: "account",
      icon: "account_circle"
    },
    {
      name: "rightsDelegation",
      url: "rights-delegation/my",
      icon: "transfer_within_a_station",
      visible: () => this.rightsDelegationMyVisible
    },
    {
      name: "logout",
      url: "auth/logout",
      icon: "logout"
    },
  ]);

  language: string;

  private rightsDelegationMyVisible = false;

  private get user(): User {
    return this.auth.currentUser;
  }

  ngOnInit() {
    this.menus = [];

    this.adminMenu.items.forEach(t => (t.text = this.app.translate(`nav.${t.name}`)));
    this.userMenu.items.forEach(t => (t.text = this.app.translate(`nav.${t.name}`)));

    this.adminMenu.items.sort((a, b) => a.text.localeCompare(b.text));

    this.addMenu(this.adminMenu);
    this.addMenu(this.userMenu);

    this.language = this.app.currentLanguage;

    this.router.events.subscribe((e) => {
      this.interceptNavigation(e as RouterEvent);
    });

    this.app.onDocumentClick().subscribe((e) => {
      if (this.mainNavOpened) return;

      const opened = this.menus.filter((t) => t.isOpened);

      if (opened.length) {
        if (
          !e.target.closest(".sub-nav-toggle") &&
          !e.target.closest(".sub-nav")
        ) {
          opened.forEach((t) => t.close());
        }
      }
    });

    this.auth.getUser().subscribe((user) => {
      this.isAuthenticated = user.isAuthenticated;
      this.isAdmin = user.isAdmin;
      this.userName = user.userName;
      this.userRoles = user.roles;

      if (user.isAuthenticated) {
        this.rightsDelegation.canDelegateMy().subscribe(
          res => this.rightsDelegationMyVisible = res,
          err => console.error('Failed to check if current user can delegate own rights.')
        );
      }
    });
  }

  /**
   * Toggle menus visibility state
   * @param menu Menu object
   */
  toggleMenu(menu: Menu) {
    this.menus.filter((t) => t !== menu).forEach((t) => t.close());
    menu.toggle();
  }

  /**
   * Toggle main navigation visibility state.
   */
  toggleMainNav() {
    this.mainNavOpened = !this.mainNavOpened;

    if (this.mainNavOpened) {
      this.menus.forEach((t) => t.close());
    }
  }

  /**
   * Switch current language
   * @param language Language to switch to
   */
  switchLanguage(language: ILanguage) {
    if (this.language === language.code) return;
    this.app.switchLanguage(language.code);
  }

  /**
   * Get visible navigation items
   * @param items Navigation item array
   */
  getVisible(items: INavLink[]): INavLink[] {
    return items && items.filter((t) => !t.visible || t.visible(t));
  }

  /**
   * Remove breaks from HTML string.
   * @param html
   */
  toSingleLine(html: string): string {
    return html.replace('<br>', ' ');
  }

  private addMenu(menu: Menu) {
    this.menus.push(menu);
  }

  private interceptNavigation(event: RouterEvent) {
    this.mainNavOpened = false;
    this.menus.forEach((t) => t.close());
  }
}
