<ng-container [formGroup]="form">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input matInput
           [formControlName]="searchControlName"
           [matAutocomplete]="auto"
           [required]="required"
           [errorStateMatcher]="employeeErrorStateMatcher"
           (input)="onSearch()">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" [displayWith]="displayFn">
      <mat-option *ngFor="let item of options" [value]="item">
        <div class="autocomplete-item-ex">
          <div>{{item.name}} {{item.surname}}</div>
          <div class="ex" *ngIf="item.position && item.department">{{item.position}} / {{item.department}}</div>
        </div>
      </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix *ngIf="!!resolvedId" (click)="$event.stopPropagation(); openProfile()">
      <mat-icon>open_in_new</mat-icon>
    </button>
  </mat-form-field>
  <input type="hidden" [formControlName]="name" [required]="required" />
</ng-container>
