import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

export enum AlertType {
  Info = "info",
  Success = "success",
  Error = "error",
  Warning = "warning",
}

export class Alert {
  constructor(
    public text: string,
    public type: AlertType = AlertType.Info,
    public title?: string
  ) {}
}

export class ConfirmOptions {
  /** Dialog body text */
  text: string;
  /** Dialog title */
  title?: string;
  /** "OK" button text */
  ok?: string;
  /** "Cancel" button text */
  cancel?: string;
}

export class Confirm {
  constructor(public options: ConfirmOptions) {}

  private subj: Subject<boolean> = new Subject<boolean>();

  listen(): Observable<boolean> {
    return this.subj.asObservable();
  }

  result(result: boolean) {
    this.subj.next(result);
  }
}

@Injectable({ providedIn: "root" })
export class AlertService {
  private alertSubject = new Subject<Alert>();
  private notifySubject = new Subject<string>();
  private confirmSubject = new Subject<Confirm>();

  /**
   * Success message display
   * @param message Message string
   * @param title Optional title string
   */
  success(message: string, title?: string) {
    this.setAlert(message, AlertType.Success, title);
  }

  /**
   * Error message display
   * @param message Message string
   * @param title Optional title string
   */
  error(message: string, title?: string) {
    this.setAlert(message, AlertType.Error, title);
  }

  /**
   * Info message display
   * @param message Message string
   * @param title Optional title string
   */
  info(message: string, title?: string) {
    this.setAlert(message, AlertType.Info, title);
  }

  /**
   * Warning message display
   * @param message Message string
   * @param title Optional title string
   */
  warning(message: string, title?: string) {
    this.setAlert(message, AlertType.Warning, title);
  }

  /**
   * Clear message
   */
  clearAlert() {
    this.setAlert(null);
  }

  /**
   * Get alert subject as observable
   */
  getAlert(): Observable<Alert> {
    return this.alertSubject.asObservable();
  }

  /**
   * Notify
   * @param message Message string
   */
  notify(message: string) {
    this.notifySubject.next(message);
  }

  /**
   * Clear current notification
   */
  clearNotification() {
    this.notify(null);
  }

  /**
   * Get notification subject as observable
   */
  getNotification(): Observable<string> {
    return this.notifySubject.asObservable();
  }

  /**
   * Confirmation
   * @param data Confirmation options
   */
  confirm(data: ConfirmOptions): Observable<boolean> {
    const confirm = new Confirm(data);
    this.confirmSubject.next(confirm);
    return confirm.listen();
  }

  /**
   * Get confirm subject as observable
   */
  getConfirm(): Observable<Confirm> {
    return this.confirmSubject.asObservable();
  }

  private setAlert(
    text: string,
    type: AlertType = AlertType.Info,
    title?: string
  ) {
    if (text !== undefined) {
      this.alertSubject.next(new Alert(text, type, title));
    }
  }
}
