import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { empty, Observable, Subject } from 'rxjs';
import { Patch } from '@shared/models/common';
import { saveAs } from 'file-saver';
import * as xlsx from 'xlsx';

export class Util {
  /**
   * Get file size in a user-friendly format.
   * @param bytes
   */
  static prettyFileSize(bytes: number): string {
    const div = 1024;
    const units = ['B', 'KB', 'MB', 'GB'];

    let unit = units[0];
    let size = bytes;
    let curr: number;

    while ((curr = size / div) > 1) {
      size = curr;
      units.shift();
    }

    return `${+size.toFixed(2)}${units.length ? units[0] : unit}`;
  }

  /**
   * Replace tokens in a string. Token format is {0..n}.
   * @param input
   * @param replacements
   */
  static formatString(input: string, replacements: any): string {
    replacements = [].concat(replacements);
    return input.replace(/{(\d+)}/g, (match, n) => replacements[n]);
  }

  /**
   * Convert base64 string to a blob.
   * @param base64
   * @param mimeType
   */
  static base64ToBlob(base64: string, mimeType: string = ''): Blob {
    const sliceSize = 1024;
    const byteChars = window.atob(base64);
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
      var slice = byteChars.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  }

  /**
   * Get formatted date string.
   * @param date
   * @param format Supported tokens: y (year), m (month), d (date), hh (hours), mm (minutes), ss (seconds).
   */
  static getDateString(date: any, format: string = 'y-m-d'): string {
    if (!date || !format) return undefined;

    const t = Util.ensureDate(date);

    if (!t) return undefined;

    const parts = {
      y: t.getFullYear() + '',
      m: ensureTwoDigits(t.getMonth() + 1),
      d: ensureTwoDigits(t.getDate()),
      hh: ensureTwoDigits(t.getHours()),
      mm: ensureTwoDigits(t.getMinutes()),
      ss: ensureTwoDigits(t.getSeconds())
    };

    return format
      .replace('y', parts.y)
      .replace('m', parts.m)
      .replace('d', parts.d)
      .replace('hh', parts.hh)
      .replace('mm', parts.mm)
      .replace('ss', parts.ss);

    function ensureTwoDigits(n: number) {
      return ('0' + n).slice(-2);
    }
  }

  /**
   * Get a date from EXIF datetime value.
   * @param exifDateTime
   */
  static fromExifDateTime(exifDateTime: string): Date {
    if (!exifDateTime) return undefined;

    const parts = exifDateTime.split(' ');
    const ymd = parts[0].split(':').map((t) => +t);
    const hms = parts[1].split(':').map((t) => +t);

    return new Date(ymd[0], ymd[1] - 1, ymd[2], hms[0], hms[1], hms[2]);
  }

  /**
   * Convert an array to dictionary.
   * @param array Source array
   * @param key Function to get a key for the dictionary item
   */
  static arrayToDictionary<T>(array: T[], key: (item: T) => string): { [key: string]: T } {
    const dict: { [key: string]: T } = {};

    array.forEach((t) => {
      const k = key(t);
      dict[k] = t;
    });

    return dict;
  }

  /**
   * Convert an array to dictionary of lists grouped by a key.
   * @param array Source array
   * @param key Function to get a key for the dictionary item
   */
  static arrayToListDictionary<T>(array: T[], key: (item: T) => string): { [key: string]: T[] } {
    const result = {};

    array.forEach((t) => {
      const k = key(t);

      if (!result[k]) result[k] = [];

      result[k].push(t);
    });

    return result;
  }

  /**
   * Remove an item from an array.
   * @param array
   * @param item
   * @param condition
   */
  static removeItemFromArray<T>(array: T[], item: T, condition?: (index: number) => boolean) {
    const i = array.indexOf(item);

    if (i > -1 && (!condition || condition(i))) {
      array.splice(i, 1);
    }
  }

  /**
   * Get the date object from the value. Useful when dealing with moment dates bound to material datepicker.
   * @param value
   */
  static ensureDate(value: any): Date {
    if (!value) return undefined;
    if (value instanceof Date) return value;
    if (value.toDate) return value.toDate();
    if (typeof value === 'string') return new Date(value);

    return undefined;
  }

  /**
   * Convert a simple key-value object to a form data.
   * @param model
   */
  static convertToFormData(model: { [key: string]: any }): FormData {
    const form = new FormData();

    for (const k in model) {
      this.appendToFormData(form, model[k], k);
    }

    return form;
  }

  /**
   * Append a value to a form data.
   * @param form
   * @param value Value to add, can be: string, number, date, array, file, object
   * @param key
   */
  static appendToFormData(form: FormData, value: any, key: string) {
    if (value === undefined || value === null) return;

    const isFile = (val) => val instanceof File || val instanceof Blob;

    if (value instanceof Array) {
      for (let i = 0; i < value.length; i++) {
        this.appendToFormData(form, value[i], isFile(value[i]) ? key : `${key}[${i}]`);
      }
    } else if (isFile(value)) {
      form.append(key, value);
    } else if (value.toISOString) {
      form.append(key, this.getDateString(value, 'y-m-d hh:mm:ss'));
    } else if (typeof value === 'object') {
      Object.keys(value).forEach((k) => {
        if (value[k] !== undefined && value[k] !== null) {
          form.append(`${key}[${k}]`, value[k]);
        }
      });
    } else {
      form.append(key, value);
    }
  }

  /**
   * Get a valid query param collection. Empty, undefined and null params are left out.
   * @param params Collection of params, which can be string, number, date or array
   */
  static getValidQueryParams(params: { [key: string]: any }): { [key: string]: any } {
    const result = {};

    for (const k in params) {
      const val = params[k];

      if (val !== undefined && val !== null && val !== '') {
        if (val.toISOString) {
          result[k] = val.toISOString().replace('T00:00:00.000Z', '');
        } else {
          if (typeof val === 'object' && 'length' in val) {
            if (val.length) {
              result[k] = val;
            }
          } else {
            result[k] = val;
          }
        }
      }
    }

    return result;
  }

  /**
   * Split array into chunks.
   * @param array
   * @param chunkSize
   */
  static arrayToChunks<T>(array: T[], chunkSize: number): T[][] {
    const result = [];

    for (let i = 0, j = array.length; i < j; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }

    return result;
  }

  /**
   * Create a safe URL by adding a http:// prefix if needed.
   * @param url
   */
  static safeUrl(url: string): string {
    if (!url) return url;

    if (!/https?:/i.test(url)) return `http://${url}`;

    return url;
  }

  /**
   * Sort an array according to another array order.
   * @param items Array to sort
   * @param ordered Target sorted array
   * @param finder Function to find items in sorted array
   */
  static orderByOther<T, TOther>(items: T[], ordered: TOther[], finder: (item: T, other: TOther) => boolean): T[] {
    let result: T[] = [];

    ordered.forEach((t) => {
      const obj = items.find((n) => finder(n, t));
      obj && result.push(obj);
    });

    const orphans = items.filter((t) => !result.includes(t));

    if (orphans.length) {
      result = result.concat(orphans);
    }

    return result;
  }

  /**
   * Download a file.
   * @param url File download URL
   * @param http HTTP client. If provided, async file download is performed, otherwise, native browser download is used.
   * @param data Data to to be posted (only when http provided)
   */
  static downloadFile(url: string, http?: HttpClient, data?: any): Observable<unknown> {
    if (http) {
      const subj = new Subject();
      let obs: Observable<HttpResponse<Blob>>;

      if (data) {
        obs = http.post(url, data, {
          responseType: 'blob',
          observe: 'response',
          withCredentials: true
        });
      } else {
        obs = http.get(url, {
          responseType: 'blob',
          observe: 'response',
          withCredentials: true
        });
      }

      obs.subscribe(
        (file) => {
          let filename = this.readFileNameFromHeaders(file.headers) || url.split('?')[0].split('/').pop();
          saveAs(file.body, filename);
          subj.next(undefined);
        },
        (err) => {
          subj.error(err);
        }
      );

      return subj.asObservable();
    } else {
      const anchor = document.createElement('a');

      anchor.setAttribute('download', 'true');
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('href', url);

      anchor.click();

      return empty();
    }
  }

  /**
   * Download multiple files at once.
   * @param url File download URL
   * @param model A model containing information of selected files to download
   * @param http HTTP client. If provided, async file download is performed, otherwise, native browser download is used.
   */
  static downloadFiles(url: string, model: any, http?: HttpClient): Observable<unknown> {
    if (http) {
      const subj = new Subject();

      http
        .post(url, model, {
          responseType: 'blob' as 'json',
          observe: 'response'
        })
        .subscribe(
          (file) => {
            let filename = this.readFileNameFromHeaders(file.headers) || url.split('?')[0].split('/').pop();
            saveAs(file.body, filename);
            subj.next(undefined);
          },
          (err) => {
            subj.error(err);
            throw err;
          }
        );

      return subj.asObservable();
    } else {
      const form = document.createElement('form');
      form.action = url;
      form.method = 'post';
      form.target = '_blank';
      form.style.display = 'none';

      model.forEach((n) => {
        const keys = Object.keys(n);

        keys.forEach((k) => {
          const input = document.createElement('input');
          input.name = k;
          input.value = n[k];

          form.appendChild(input);
        });
      });

      document.body.appendChild(form);

      form.submit();
      form.remove();

      return empty();
    }
  }

  /**
   * Read file name from HTTP headers.
   * @param headers
   */
  static readFileNameFromHeaders(headers: HttpHeaders): string {
    let filename = undefined;

    const disp = headers.get('content-disposition');

    const utf8Match = disp.match(/filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i);

    if (utf8Match.length > 1) {
      filename = decodeURIComponent(utf8Match[1]);
    } else {
      const match = disp.match(/filename="?([^"]+)"?;/);

      if (match.length > 1) {
        filename = match[1];
      }
    }

    return filename;
  }

  /**
   * Create PATCH replace operations.
   * @param data
   */
  static createPatchOperations<T>(data: Patch<T>): { op: string, value: any, path: string }[] {
    const operations = [];

    if (!data) return operations;

    for (const prop in data) {
      operations.push({
        op: 'replace',
        value: data[prop],
        path: `/${prop}`
      });
    }

    return operations;
  }

  /**
   * Export grid data to file.
   * @param data Data row values
   * @param filename Target file name
   * @param header Header row
   * @param options
   * @option sheetName Excel sheet name. Defaults to "Sheet1".
   * @option delimiter CSV delimiter. Defaults to ";".
   */
  static exportGridAs(data: any[][], header: string[], filename: string, options?: {
    delimiter?: string,
    sheetName?: string
  }) {
    const defaults = {
      delimiter: ';',
      sheetName: 'Sheet1'
    };

    options = {
      ...defaults,
      ...options
    };

    const format = filename.split('.').pop().toLowerCase();

    switch (format) {
      case 'csv':
        const replacer = (key, value) => value === null ? '' : value;
        const csv = data.map(row => row.map(n => {
          if (typeof n == 'number') return n;
          return JSON.stringify(n, replacer).slice(1, -1);
        }).join(options.delimiter));
        if (header?.length) {
          csv.unshift(header.join(options.delimiter));
        }

        const csvArray = "\ufeff" + csv.join('\r\n');

        const blob = new Blob([csvArray], { type: 'text/csv' })
        saveAs(blob, filename);

        break;

      case 'xlsx':
        const rows = header?.length ? [header].concat(data) : data;
        const ws: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(rows);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, ws, options.sheetName);
        xlsx.writeFile(wb, filename);

        break;

      default:
        throw new Error('Cannot export data: target format not supported.');
    }
  }
}
