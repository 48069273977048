import { IClassifierValue } from './Classifier';
import { IAuditUser } from './common';
import { IPlanningPeriod } from './PlanningPeriod';

export enum StudyPlanCourseAlertLevel {
  CourseChangeMinor = 'CourseChangeMinor',
  CourseChangeImportant = 'CourseChangeImportant',
  CourseChangeCritical = 'CourseChangeCritical',
  CourseChangeFundamental = 'CourseChangeFundamental'
}

export interface IStudyPlan {
  id: number;
  currentVersion: number;
  isStandard: boolean;
  implementedProgramme: {
    id: number;
    name: string;
    nameEn: string;
    studyType: IClassifierValue;
    studyForm: IClassifierValue;
    language: IClassifierValue;
  },
  planningPeriod?: IPlanningPeriod;
  flow: IClassifierValue;
  studyProgramme: {
    id: number;
    name: string;
    nameEn: string;
    lqfLevel: IClassifierValue;
    targetAudience: IClassifierValue;
  }
}

export interface IStudyPlanListItem {
  id: number;
  isStandard: boolean;
  planningPeriod: IPlanningPeriod;
  isEmpty: boolean;
  version?: number;
  versionId?: number;
  flow: IClassifierValue;
  status: IClassifierValue;
  approvalStatus: IClassifierValue;
  startDate?: Date;
  endDate?: Date;
  modifiedBy: IAuditUser;
  periodCount?: number;
  actualStartDate?: Date;
  currentVersion: IStudyPlanVersion;
  approvedVersion?: IStudyPlanVersion;
}

export interface IStudyPlanVersion {
  id: number;
  version: number;
  status: IClassifierValue;
  approvalStatus: IClassifierValue;
  periodCount: number;
  startDate?: Date;
  endDate?: Date;
  actualStartDate?: Date;
  modified: Date;
  modifiedBy: IAuditUser;
  isAccredited?: boolean;
  isReadOnly?: boolean;
  alertLevel?: StudyPlanCourseAlertLevel;
}

export interface IStudyPlanCourseTableCategories {
  code: string;
  current: number;
}

export interface IStudyPlanCourse {
  id: number;
  idmId: string;
  courseId: number;
  versionId: number;
  planVersionId: number;
  version: number;
  name: string;
  nameEn: string;
  code: string;
  creditPoints: number;
  ects: number;
  isPlaceholder: boolean;
  category: IClassifierValue;
  notes: string;
  parts: IStudyPlanCoursePart[];
  specializations: IClassifierValue[];
  alertLevel?: StudyPlanCourseAlertLevel;
  alertDetails?: {
    CourseChanges: {
      Type: string,
      Level: StudyPlanCourseAlertLevel,
      Before: { Value: any, ValueEn?: any },
      After: { Value: any, ValueEn?: any },
      Period?: number,
      Part?: number
    }[]
  };
  isRemoved?: boolean;
}

export interface IStudyPlanCoursePart {
  id: number;
  partId: number;
  periodId: number;
  code: string;
  index: number;
  implementerId: string;
  implementerName: string;
  lectureCount?: number;
  lectureDuration?: number;
  lectureHours?: number;
  lessonCount?: number;
  lessonDuration?: number;
  lessonHours?: number;
  totalHours?: number;
  seminarCount?: number;
  weekCount?: number;
  creditPoints: number;
  ects: number;
  finalTestType: string;
  finalTestTypeEn: string;
  module?: number;
}

export interface IStudyPlanCreateModel {
  implementedProgrammeId: number;
  flowId: string;
  planningPeriodId?: number;
}

export interface IStudyPlanAddVersionModel {
  startDate?: Date;
  endDate?: Date;
  actualStartDate?: Date;
}

export interface IStudyPlanUpdateVersionModel {
  startDate: Date;
  endDate: Date;
}

export interface IStudyPlanCopyVersionModel {
  flowId: string;
  planningPeriodId?: number;
}

export interface ICourseAddModel {
  courseCode?: string;
  languageId?: string;
  placeholderId?: number;
  placeholderName?: string;
  placeholderEcts?: number;
  categoryId?: string;
  notes?: string;
  specializationIds?: string[];
  parts?: ICourseAddPartModel[][];
  clone?: boolean;
}

export interface ICourseAddPartModel {
  index: number;
  periodId: number;
  module?: number;
}

export interface ICourseEditModel {
  categoryId: string;
  notes: string;
  specializationIds: string[];
  parts: ICourseAddPartModel[];
}

export interface IPeriodEditModel {
  ectsA: number;
  ectsB: number;
  ectsC: number;
}

export interface IStudyPlanPeriod {
  id: number;
  planVersionId: number;
  index: number;
  creditPointsA: number;
  creditPointsB: number;
  creditPointsC: number;
  ectsA: number;
  ectsB: number;
  ectsC: number;
  period: IPlanningPeriod;
  isPast?: boolean;
  coursePartCount: number;
}

export interface IStudyPlanVersionSearchResult {
  id: number;
  version: number;
  isStandard: boolean;
  period: IPlanningPeriod;
  flow: IClassifierValue;
  approvalStatus: IClassifierValue;
  studyProgramme: {
    id: number;
    code: string;
    name: string;
    nameEn: string;
    planningType: IClassifierValue & { abbr: string };
  };
  implementedProgramme: {
    id: number;
    code: string;
    name: string;
    nameEn: string;
  }
}

export interface IStudyPlanVersionCompare {
  id: number;
  planId: number;
  version: number;
  isStandard: boolean;
  flow: IClassifierValue;
  approvalStatus: IClassifierValue;
  period?: IPlanningPeriod;
  implementedProgramme: IStudyPlanVersionCompareImplementedProgramme;
  studyProgramme: IStudyPlanVersionCompareStudyProgramme;
  courses: IStudyPlanVersionCompareCourse[];
}

export interface IStudyPlanVersionCompareCourse {
  idmId: string;
  code: string;
  name: string;
  nameEn: string;
  creditPoints: number;
  ects: number;
  category: IClassifierValue;
  specializations: IClassifierValue[];
  implementer: string;
  periods: IStudyPlanVersionComparePeriod[];
  isPlaceholder: boolean;
}

export interface IStudyPlanVersionComparePeriod {
  index: number;
  period?: IPlanningPeriod;
  module?: number;
}

export interface IStudyPlanVersionCompareImplementedProgramme {
  id: number;
  code: string;
  name: string;
  nameEn: string;
  duration: number;
  language: IClassifierValue;
  location: IClassifierValue;
  studyForm: IClassifierValue;
  studyType: IClassifierValue;
}

export interface IStudyPlanVersionCompareStudyProgramme {
  id: number;
  planningType: IClassifierValue;
}

export interface IStudyPlanPendingApproval {
  id: number;
  versionId: number;
  isStandard: boolean;
  period: IPlanningPeriod;
  version: number;
  flow: IClassifierValue;
  status: IClassifierValue;
  date: Date | string;
  supervisorName: string;
  supervisorId: string;
  initiator: IAuditUser;
  implementedProgramme: IImplementedProgrammeData;
}

export interface IStudyPlanFinishedApproval {
  id: number;
  versionId: number;
  isStandard: boolean;
  period: IPlanningPeriod;
  version: number;
  flow: IClassifierValue;
  status: IClassifierValue;
  date?: Date;
  supervisorName: string;
  supervisorId: string;
  initiator: IAuditUser;
  responder: IAuditUser;
  comment: string;
  action: 'approve' | 'reject' | 'rejectWithChanges' | 'acceptChanges';
  emailSent?: Date | string;
  implementedProgramme: IImplementedProgrammeData;
}

export interface IImplementedProgrammeData {
  id: number;
  code: string;
  name: string;
  nameEn: string;
  duration: number;
  language: IClassifierValue;
  studyForm: IClassifierValue;
  studyType: IClassifierValue;
  location: IClassifierValue;
}

export interface IStudyPlanCopyVersionResult {
  id: number;
  planId: number;
  version: number;
  approvalStatus: IStudyPlanCopyClassifierInfo;
  courses: IStudyPlanCopyCourseInfo[];
}

export interface IStudyPlanCopyClassifierInfo {
  id: string;
  value: string;
  valueEn: string;
  code: string;
}

export interface IStudyPlanCopyCourseInfo {
  id: number;
  versionId: number;
  version: number;
  name: string;
  nameEn: string;
  code: string;
  creditPoints: number;
  ects: number;
  implementerName: string;
  category: IStudyPlanCopyClassifierInfo;
  addedTo: IStudyPlanCopyPeriodInfo[];
  removedFrom: IStudyPlanCopyPeriodInfo[];
  oldPlanCourseId: number;
  newPlanCourseId?: number;
}

export interface IStudyPlanCopyPeriodInfo {
  name: string;
  nameEn: string;
  index: number;
}

export interface IStudyPlanAlertItem {
  id: number;
  version: boolean;
  versionId: number
  period: IPlanningPeriod;
  studyProgramme: { id: number, code: string, name: string, nameEn: string };
  implementedProgramme: { id: number, code: string, name: string, nameEn: string };
  affectedPeriods: IPlanningPeriod[];
}

export interface ICoursePlanning {
  id: number;
  version: number;
  versionId: number;
  code: string;
  name: string;
  nameEn: string;
  implementerName: string;
  implementerId: string;
  creditPoints: number;
  ects: number;
  plan: ICoursePlanningPlan;
  programme: ICoursePlanningProgramme;
  category: IClassifierValue;
  language: IClassifierValue;
  parts: ICoursePlanningPart[];
}

export interface ICoursePlanningPlan {
  id: number;
  versionId: number;
  version: number;
  period: IPlanningPeriod;
  isStandard: boolean;
  approvalStatus: IClassifierValue;
  flow: IClassifierValue;
}

export interface ICoursePlanningProgramme {
  id: number;
  code: string;
  name: string;
  nameEn: string;
  language: IClassifierValue;
  studyForm: IClassifierValue;
  studyType: IClassifierValue;
  duration: number;
}

export interface ICoursePlanningPart {
  id: number;
  index: number;
  period: IPlanningPeriod;
  periodIsPast: boolean;
  periodId: number;
  periodIndex: number;
}

export interface IStudyPlanFinishResult {
  totalFinished: number;
  unfinishedIn: {
    studyProgramme: {
      id: number,
      code: string,
      name: string,
      nameEn: string
    },
    implementedProgramme: {
      id: number,
      code: string,
      name: string,
      nameEn: string
    }
  }[];
}
