import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from '../material.module';
import { AlertComponent } from './components/alert/alert.component';
import { ConfirmComponent } from './components/alert/confirm.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { EmployeeInputComponent } from './components/employee-input/employee-input.component';
import { HyperlinkEditComponent } from './components/hyperlink/edit.component';
import { PageNotFoundComponent } from './components/pages/not-found.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextMoreLessComponent } from './components/text-more-less/text-more-less.component';
import { ExpandableNodeDirective, NodeDirective, TreeComponent as TreeExtComponent } from './components/tree-ext/tree.component';
import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { AppDatePipe } from './pipes/date.pipe';
import { FormatStringPipe } from './pipes/format-string.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PagePipe } from './pipes/page.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TranslatePropertyPipe } from './pipes/translate-property.pipe';
import { TreeNodeComponent as TreeNodeExtComponent } from './components/tree-node-ext/tree-node.component';
import { TreeComponent, TreeNodeComponent } from './components/tree/tree.component';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { EmployeeLinkComponent } from './components/employee-link/employee-link.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { MatSelectDirective } from './directives/mat-select.directive';
import { InputMaxLengthComponent } from './components/input-length/input-length.component';
import { MaxLengthErrorDirective } from './directives/max-length-error.directive';

const COMPONENTS = [
  AlertComponent,
  ConfirmComponent,
  AttachmentsComponent,
  FileInputComponent,
  EmployeeInputComponent,
  EmployeeLinkComponent,
  HyperlinkEditComponent,
  PageNotFoundComponent,
  SpinnerComponent,
  TextMoreLessComponent,
  TreeComponent,
  TreeNodeComponent,
  TreeExtComponent,
  TreeNodeExtComponent,
  SearchInputComponent,
  SelectSearchComponent,
  InputMaxLengthComponent
];

const DIRECTIVES = [
  MatSelectDirective,
  MaxLengthErrorDirective
];

const PIPES = [
  SafeHtmlPipe,
  SafeUrlPipe,
  AppDatePipe,
  FormatStringPipe,
  ArrayJoinPipe,
  OrderByPipe,
  PagePipe,
  TranslatePropertyPipe,
  ExpandableNodeDirective,
  NodeDirective,
  TruncateStringPipe
];

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ]
})
export class SharedModule { }
