import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  constructor(private hasError: (control?: FormControl) => boolean, private mustTouch: boolean = true) { }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && (!this.mustTouch || control.touched || form.touched) && this.hasError(control);
  }
}
