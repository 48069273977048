import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Alert, AlertType } from '@shared/services/alert.service';

@Component({
  selector: 'app-alert',
  template: `
        <div class="alert-dialog alert-dialog-{{data.type.toLowerCase()}}">
            <h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
            <h2 mat-dialog-title *ngIf="isError && !data.title">{{'common.errorTitle' | translate}}</h2>
            <mat-dialog-content>
                <div [innerHTML]="data.text | safeHtml"></div>
            </mat-dialog-content>
            <mat-dialog-actions>
                <button mat-stroked-button mat-dialog-close>{{'btn.close' | translate}}</button>
            </mat-dialog-actions>
        </div>`,
  styles: ['.mat-dialog-actions { justify-content: center }']
})
export class AlertComponent {
  constructor(public dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: Alert) {
    this.isError = this.data.type === AlertType.Error;
  }

  public isError: boolean;
}
