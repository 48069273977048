import { Component, OnInit, HostListener, Inject } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationCancel,
  NavigationError,
  NavigationEnd,
  RoutesRecognized
} from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Bowser from "bowser";

import { AppService } from './shared/services/app.service';
import { AuthService } from './shared/services/auth.service';
import { ParameterService } from './shared/services/parameter.service';
import { IdleTimer, IdleTimerFactory } from './core/IdleTimer';
import { User } from './shared/models/Identity';
import { FontSize } from './core/FontSize';
import { ConfirmComponent } from './shared/components/alert/confirm.component';
import { parameterCodes } from './shared/models/Parameter';
import { Alert, AlertType, Confirm, ConfirmOptions } from './shared/services/alert.service';
import { AlertComponent } from './shared/components/alert/alert.component';
import { cache, cacheKeys } from './shared/cache';
import { WindowToken } from './core/WindowToken';
import { IBreadcrumb } from './core/Breadcrumb';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private app: AppService,
    private auth: AuthService,
    private params: ParameterService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private idleTimerFactory: IdleTimerFactory,
    @Inject(WindowToken) private window: Window
  ) {
    this.idleTimer = this.idleTimerFactory.create({
      onStart: () => {
        if (this.idleLogoutTimeout) {
          clearTimeout(this.idleLogoutTimeout);
        }

        if (this.idleDialog) {
          this.idleDialog.close();
        }
      },
      onStop: () => {
        if (this.idleLogoutTimeout) {
          clearTimeout(this.idleLogoutTimeout);
        }

        if (this.idleDialog) {
          this.idleDialog.close();
        }
      },
      onTimeout: () => {
        this.idleLogoutTimeout = setTimeout(() => {
          this.router.navigate(['/auth/logout']);
        }, this.secondsToForceLogout * 1000);

        this.idleDialog = this.showIdle(
          () => {
            if (this.idleLogoutTimeout) {
              clearTimeout(this.idleLogoutTimeout);
            }

            this.idleTimer.reset();
          },
          () => {
            if (this.idleLogoutTimeout) {
              clearTimeout(this.idleLogoutTimeout);
            }

            this.router.navigate(['/auth/logout']);
          });
      }
    });
  }

  user: User;
  fontSize = FontSize.Normal;
  breadcrumbs: IBreadcrumb[] = [];

  get isLoading(): boolean {
    return this.app.isLoading();
  }

  private hasErrorDialog: boolean;
  private appTitle: string;
  private pageTitle: string;
  private currentRoute: string;
  private isRouteReload: boolean;

  private readonly idleTimer: IdleTimer;
  private readonly secondsToForceLogout: number = 30;
  private idleTimerStarted: boolean;
  private idleDialog: MatDialogRef<ConfirmComponent>;
  private idleLogoutTimeout;

  ngOnInit() {
    this.checkBrowser();

    this.app.alerts.getAlert().subscribe((data) => this.showAlert(data));
    this.app.alerts.getNotification().subscribe((data) => this.showNotification(data));
    this.app.alerts.getConfirm().subscribe((data) => this.showConfirm(data));

    this.auth.getUser().subscribe((user) => {
      this.user = user;

      if (user.isAuthenticated) {
        // refresh parameters
        this.params.get().subscribe((data) => {
          const idleTimeoutSeconds = +this.params.findValue(parameterCodes.idleTimeout, data);
          this.startTimer(idleTimeoutSeconds * 1000);
        });
      } else {
        this.idleTimer.stop();
      }
    });

    this.app.getFontSize().subscribe((size) => {
      if (size) this.fontSize = size;
    });

    this.params.get().subscribe((data) => {
      this.appTitle = this.params.findValue(parameterCodes.appTitle, data);
      this.setTitle();
    });

    this.app.getTitle().subscribe((title) => {
      this.pageTitle = title;
      this.setTitle();
    });

    this.app.getBreadcrumbs().subscribe(items => {
      setTimeout(() => {
        const root = items.find(t => t.route === '.');

        if (root) {
          const parts = this.currentRoute.split('/');
          root.route = parts.slice(0, -1);
        }

        this.breadcrumbs = items;
      }, 1);
    });
  }

  onRouterOutletActivate(component) {
    // TODO throws ExpressionChanged...
    //const styled = <IPageStyle>component;
    //this.contentUnwrap = styled.pageContentUnwrap;

    this.router.events.subscribe((e) => {
      this.interceptNavigation(e);
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    this.app.documentClick(event);
  }

  private showAlert(alert: Alert) {
    // Show only one error dialog at a time
    const isError = alert.type === AlertType.Error;

    if (!isError || !this.hasErrorDialog) {
      const dialogRef = this.dialog.open(AlertComponent, {
        data: alert,
        disableClose: true
      });

      if (isError) {
        this.hasErrorDialog = true;
        dialogRef.beforeClosed().subscribe(() => {
          this.hasErrorDialog = false;
        });
      }
    }
  }

  private showConfirm(confirm: Confirm) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: confirm.options,
      disableClose: true,
      maxWidth: 800
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      confirm.result(result);
    });
  }

  private showNotification(text: string) {
    this.snackBar.open(text, ' ', {
      duration: 5000,
      panelClass: 'app-notification',
      verticalPosition: 'top'
    });
  }

  private showIdle(onContinue: () => void, onLogout: () => void) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: <ConfirmOptions>{
        text: this.app.translate('auth.sessionExpires'),
        ok: this.app.translate('auth.continueSession'),
        cancel: this.app.translate('auth.endSession'),
        title: this.app.translate('auth.userSession')
      },
      disableClose: true,
      maxWidth: 600
    });

    dialogRef.afterClosed().subscribe((result?: boolean) => {
      if (result !== undefined) {
        if (result) {
          onContinue();
        } else {
          onLogout();
        }
      }
    });

    return dialogRef;
  }

  private interceptNavigation(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      if (event.url == '/_') {
        return;
      }

      if (!this.isRouteReload && event.url == this.router.url) {
        this.isRouteReload = true;
        const url = event.url;

        this.router.navigateByUrl('/_', { skipLocationChange: true }).then(() => {
          this.isRouteReload = false;
          this.router.navigateByUrl(url);
        });

        return;
      }

      this.app.clearLoading();
      this.dialog.closeAll();
    }

    if (event instanceof NavigationCancel) {
      this.app.clearLoading();
    }

    if (event instanceof NavigationError) {
      this.app.clearLoading();
    }

    if (event instanceof NavigationEnd) {
      this.window.scroll(0, 0);

      const route = event.url.split('?')[0];

      if (route !== this.currentRoute) {
        this.pageTitle = undefined;
        this.setTitle();
      }

      this.currentRoute = route;

      this.app.addRouteHistory(route);
    }

    this.checkModule(event);
  }

  private setTitle() {
    document.title = (this.pageTitle ? `${this.pageTitle} | ${this.appTitle}` : this.appTitle) || '...';
  }

  private startTimer(interval: number) {
    if (this.idleTimerStarted || !interval) return;
    this.idleTimerStarted = true;
    this.idleTimer.start(interval);
  }

  private checkBrowser() {
    const cacheKey = 'browserChecked';

    if (sessionStorage.getItem(cacheKey) !== 'true') {
      sessionStorage.setItem(cacheKey, 'true');

      const browser = Bowser.getParser(window.navigator.userAgent);

      const isValid = browser.satisfies({
        chrome: ">=78",
        firefox: ">=70",
        edge: ">=79",
        safari: ">=13"
      });

      if (!isValid) {
        this.showAlert({
          text: this.app.translate('common.browserSupportDesc'),
          type: AlertType.Warning,
          title: this.app.translate('common.browserSupportTitle')
        });
      }
    }
  }

  private checkModule(event: RouterEvent) {
    if (event instanceof RoutesRecognized) {
      const route = event.state.root.firstChild;
      const path = route.routeConfig.path.toLowerCase();

      if (path != 'study-programmes') {
        cache.remove(cacheKeys.studyProgrammeFilterValues);
        cache.remove(cacheKeys.studyProgrammeFilterOptions);
        cache.remove(cacheKeys.studyProgrammeSearch);
      }

      if (path != 'implemented-programmes') {
        cache.remove(cacheKeys.implementedProgrammeFilterValues);
        cache.remove(cacheKeys.implementedProgrammeFilterOptions);
        cache.remove(cacheKeys.implementedProgrammeSearch);
      }
    }
  }
}
