import { Pipe } from '@angular/core';

@Pipe({
  name: 'page'
})
export class PagePipe {
  transform(data: any[], page: number, size: number): any {
    if ((data || []).length <= 1) return data;

    let result = [];

    for (let i = (page - 1) * size; i < data.length; i++) {
      if (result.length === size) break;

      result.push(data[i]);
    }

    return result;
  }
}
