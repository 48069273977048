import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from '@shared/components/pages/not-found.component';
import { UserRoleGuard } from '@shared/guards/user-role.guard';
import { permissions } from '@shared/models/Identity';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/main/main.module').then((module) => module.MainPageModule)
  },
  {
    path: 'admin',
    canActivate: [UserRoleGuard],
    loadChildren: () => import('./features/admin/admin.module').then((module) => module.AdminModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then((module) => module.AuthModule)
  },
  {
    path: 'study-directions',
    canActivate: [UserRoleGuard],
    data: {
      rights: [permissions.studyDirectionView, permissions.studyDirectionViewActual]
    },
    loadChildren: () =>
      import('./features/study-direction/study-direction.module').then((module) => module.StudyDirectionModule)
  },
  {
    path: 'study-programmes',
    canActivate: [UserRoleGuard],
    data: {
      rights: [permissions.studyProgrammeView]
    },
    loadChildren: () =>
      import('./features/study-programme/study-programme.module').then((module) => module.StudyProgrammeModule)
  },
  {
    path: 'implemented-programmes',
    canActivate: [UserRoleGuard],
    data: {
      rights: [permissions.implementedProgrammeView]
    },
    loadChildren: () =>
      import('./features/implemented-programme/implemented-programme.module').then((module) => module.ImplementedProgrammeModule)
  },
  {
    path: 'study-plan-compare',
    canActivate: [UserRoleGuard],
    data: {
      rights: [permissions.studyPlanCompare]
    },
    loadChildren: () =>
      import('./features/study-plan-compare/study-plan-compare.module').then((module) => module.StudyPlanCompareModule)
  },
  {
    path: 'account',
    canActivate: [UserRoleGuard],
    loadChildren: () =>
      import('./features/account/account.module').then((module) => module.AccountModule)
  },
  {
    path: 'rights-delegation',
    canActivate: [UserRoleGuard],
    loadChildren: () =>
      import('./features/rights-delegation/rights-delegation.module').then((module) => module.RightsDelegationModule)
  },
  {
    path: 'requirement-documents',
    canActivate: [UserRoleGuard],
    loadChildren: () => import('./features/requirement-document/requirement-document.module').then(m => m.RequirementDocumentModule)
  },
  //{
  //  path: '',
  //  redirectTo: '/main',
  //  pathMatch: 'full'
  //},
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
