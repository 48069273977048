import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncateString",
})
export class TruncateStringPipe implements PipeTransform {
  /**
   * Truncates provided string. If the string is shorter than the limit, then returns the original string.
   * @param value - the string to be truncated.
   * @param limit - the limit for the truncation
   * @param end - the string to be appended to the cut part.
   * @returns a truncated string or the original one if the length is lesser than the limit.
   */
  transform(value: string, limit = 20, end = "..."): string {
    if (!value) {
      return value;
    }
    const valueStr = value.toString();
    if (valueStr.length <= limit) {
      return value;
    }
    return valueStr.slice(0, limit) + end;
  }
}
