import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmOptions } from '@shared/services/alert.service';

@Component({
  template: `
        <div class="confirm-dialog">
            <h2 mat-dialog-title *ngIf="title">{{title}}</h2>
            <mat-dialog-content><div [innerHTML]="text | safeHtml"></div></mat-dialog-content>
            <mat-dialog-actions>
                <button mat-flat-button [mat-dialog-close]="true" color="primary">
                    <ng-container *ngIf="!okText">{{'btn.ok' | translate}}</ng-container>
                    <ng-container *ngIf="okText">{{okText}}</ng-container>
                </button>
                <button mat-stroked-button mat-dialog-close *ngIf="cancelText !== ''">
                    <ng-container *ngIf="!cancelText">{{'btn.cancel' | translate}}</ng-container>
                    <ng-container *ngIf="cancelText">{{cancelText}}</ng-container>
                </button>
            </mat-dialog-actions>
        </div>`,
  styles: ['.mat-dialog-actions { justify-content: center }']
})
export class ConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmOptions
  ) {
    this.title = data.title;
    this.text = data.text;
    this.okText = data.ok;
    this.cancelText = data.cancel;
  }

  title: string;
  text: string;
  okText: string;
  cancelText: string;
}
