<div class="tree-node">
  <ng-container
    [ngTemplateOutlet]="expandableNodeTemplate.templateRef"
    *ngIf="isExpandable; else simpleNode"
    [ngTemplateOutletContext]="{$implicit: node}">
  </ng-container>

  <ng-template #simpleNode>
    <ng-container [ngTemplateOutlet]="nodeTemplate.templateRef" [ngTemplateOutletContext]="{$implicit: node}"></ng-container>
  </ng-template>

  <ng-container *ngIf="isExpandable && node.isExpanded">
    <div class="nested-tree-node">
      <app-tree-node-ext
        *ngFor="let child of node.children"
        [node]="child"
        [nodeTemplate]="nodeTemplate"
        [expandableNodeTemplate]="expandableNodeTemplate">
      </app-tree-node-ext>
    </div>
  </ng-container>
</div>
