import { Component, OnInit, ChangeDetectionStrategy, Input, ContentChild, Directive, TemplateRef, Optional } from '@angular/core';
import { ITree, ITreeNode } from './tree.model';

@Directive({
  selector: "[appTreeNode]",
})
export class NodeDirective<T> {
  node: ITreeNode<T>;
  constructor (@Optional() public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: "[appExpandableTreeNode]",
})
export class ExpandableNodeDirective<T> extends NodeDirective<T> {}

@Component({
  selector: 'app-tree-ext',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent<T> implements OnInit{
  @Input() tree: ITree<T>;
  @Input() checkExpandable: (node: ITreeNode<T>) => boolean;
  @ContentChild(ExpandableNodeDirective, {static: true}) expandableNodeDirective: NodeDirective<any>;
  @ContentChild(NodeDirective, {static: true}) nodeDirective: NodeDirective<any>;

  constructor() { }

  ngOnInit(): void {
  }
}
