import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppService } from '@shared/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private app: AppService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lang = this.app && this.app.currentLanguage;

    if (lang) {
      req = req.clone({
        setHeaders: {
          ['x-app-lang']: lang
        }
      });
    }

    return next.handle(req);
  }
}
