import { Directive, OnInit } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { AppService } from "../services/app.service";

@Directive({
  selector: 'mat-select'
})
export class MatSelectDirective implements OnInit {
  constructor(private select: MatSelect, private app: AppService) { }

  ngOnInit() {
    this.app.beforeCheckFormValidity.subscribe(form => {
      const selected: MatOption[] = [].concat(this.select.selected || []);

      if (this.select.value && !selected.length) {
        // Reset and invalidate a select control which value is not actually present in the provided options.
        // E.g. a previously saved value isn't actual anymore (removed from the option data source).
        this.select.ngControl.reset(this.select.multiple ? [] : undefined);
      }
    });
  }
}
