<div class="app">
  <div class="app-curtain" *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <app-header></app-header>
  <main class="app-body font-size-{{fontSize}}">
    <nav class="breadcrumbs" *ngIf="breadcrumbs">
      <a [routerLink]="['/']" class="breadcrumb-link">{{'nav.home' | translate}}</a>
      
      <ng-container *ngFor="let n of breadcrumbs; let i = index">
        <span class="breadcrumb-sep"> &rsaquo; </span>
        <a [routerLink]="n.route" [queryParams]="n.query" class="breadcrumb-link" *ngIf="n.route">{{n.label | translate}}</a>
        <span class="breadcrumb-label" *ngIf="!n.route">{{n.label | translate}}</span>
      </ng-container>
    </nav>

    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </main>
  <app-footer></app-footer>
</div>
