import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injector, APP_INITIALIZER } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from './material.module';

// core
import { AppErrorHandler } from './core/AppErrorHandler';
import { AuthorizeInterceptor } from './core/AuthorizeInterceptor';
import { LanguageInterceptor } from './core/LanguageInterceptor';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { WindowToken, windowProvider } from './core/WindowToken';

// misc
import { AppService } from './shared/services/app.service';
import { AuthService } from './shared/services/auth.service';
import { ParameterService } from './shared/services/parameter.service';

// routes
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MainPageModule } from './features/main/main.module';
import { parameterCodes } from './shared/models/Parameter';

export function appInitializerFactory(app: AppService, auth: AuthService, parameterService: ParameterService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      // Wait for language translation to be initialized.
      // This solves the problem with instant translations.
      // See https://github.com/ngx-translate/core/issues/517
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      const paginatorOpts = injector.get(MAT_PAGINATOR_DEFAULT_OPTIONS);

      auth.authenticate().subscribe(() => {
        console.log('Authentication checked.');

        parameterService.get().subscribe(params => {
          const pageSize = parameterService.findValue(parameterCodes.pageSize, params);

          if (paginatorOpts && pageSize) {
            paginatorOpts.pageSize = +pageSize;
          }

          locationInitialized.then(() => {
            const langToSet = app.getUserLanguage();
            app.translator.setDefaultLang(langToSet);
            app.translator.use(langToSet).subscribe(
              () => {
                console.info(`Successfully initialized '${langToSet}' language.`);
              },
              (err) => {
                console.error(`Problem with '${langToSet}' language initialization.`);
                console.error(err);
              },
              () => {
                resolve(null);
              }
            );
          });
        });
      }, err => {
        resolve(null);
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    MainPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    MaterialModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppService, AuthService, ParameterService, Injector],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: WindowToken, useFactory: windowProvider },
    UserRoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
