import { Component, Input } from "@angular/core";
import { INavLink } from "../header.component";

@Component({
  selector: "app-header-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["../header.component.scss"],
})
export class HeaderMenuComponent {
  @Input() items: INavLink[] = [];
  @Input() style: string;

  /**
   * Get visible navigation items
   * @param items Navigation item array
   */
  getVisible(items: INavLink[]): INavLink[] {
    return items && items.filter((t) => !t.visible || t.visible(t));
  }
}
