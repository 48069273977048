<div class="wrapper-sm">
  <header>
    <h1>{{'common.errorTitle' | translate}}</h1>
  </header>

  <div class="box">
    <div class="alert alert-danger">
      {{'error.pageNotFound' | translate}}
    </div>
    <div class="form-actions">
      <a mat-button [routerLink]="['/']" class="pull-right">{{'btn.toHome' | translate}}</a>
    </div>
  </div>
</div>
