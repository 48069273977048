import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  IRightsDelegation,
  IStudyProgrammeOption,
  IRightsDelegationCreateModel,
  IRightsDelegationUpdateModel,
  IImplementedProgrammeOption,
} from "@shared/models/RightsDelegation";

import { environment as env } from "src/environments/environment";

/**
 * Rights delegation service
 */
@Injectable({ providedIn: "root" })
export class RightsDelegationService {
  constructor(private http: HttpClient) {}

  get apiUrl(): string {
    return `${env.apiUrl}/rightsDelegation`;
  }

  /**
   * Get rights delegations associated with study programme.
   * @param any Return delegations granted by any user. By default, only delegations granted by current user are returned.
   */
  getWithStudyProgramme(any?: boolean): Observable<IRightsDelegation[]> {
    const url = `${this.apiUrl}/withStudyProgramme`;
    return this.http.get<IRightsDelegation[]>(url, {
      params: this.createQueryParams(any),
    });
  }

  /**
   * Get rights delegations associated with implemented programme.
   * @param any Return delegations granted by any user. By default, only delegations granted by current user are returned.
   */
  getWithImplementedProgramme(any?: boolean): Observable<IRightsDelegation[]> {
    const url = `${this.apiUrl}/withImplementedProgramme`;
    return this.http.get<IRightsDelegation[]>(url, {
      params: this.createQueryParams(any),
    });
  }

  /**
   * Get available study programmes.
   * @param any Return all study programmes. By default, only study programmes where current user is a supervisor are returned.
   */
  getStudyProgrammeOptions(any?: boolean): Observable<IStudyProgrammeOption[]> {
    const url = `${this.apiUrl}/studyProgrammeOptions`;
    return this.http.get<IStudyProgrammeOption[]>(url, {
      params: this.createQueryParams(any),
    });
  }

  /**
   * Get available implemented programmes.
   * @param any Return all implemented programmes. By default, only implemented programmes where current user is a supervisor are returned.
   */
  getImplementedProgrammeOptions(any?: boolean): Observable<IImplementedProgrammeOption[]> {
    const url = `${this.apiUrl}/implementedProgrammeOptions`;
    return this.http.get<IImplementedProgrammeOption[]>(url, {
      params: this.createQueryParams(any),
    });
  }

  /**
   * Add rights delegation
   * @param data Rights delegation edit model
   * @returns Http request observable returns rights delegation id
   */
  add(data: IRightsDelegationCreateModel): Observable<number> {
    const url = `${this.apiUrl}`;
    return this.http.post<number>(url, data);
  }

  /**
   * Upadate rights delegation
   * @param id Rights delegation id
   * @param data Rights delegation edit model
   * @returns Http request observable
   */
  update(id: number, data: IRightsDelegationUpdateModel): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.put(url, data);
  }

  /**
   * Cancel rights delegation
   * @param id Rights delegation id
   * @returns Http request observable
   */
  cancel(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}/cancel`;
    return this.http.put(url, null);
  }

  /**
   * Check if current user has own right delegation options.
   * @returns True if user does have right delegation options
   */
  canDelegateMy(): Observable<boolean> {
    const url = `${this.apiUrl}/canDelegateMy`;
    return this.http.get<boolean>(url);
  }

  private createQueryParams(any?: boolean) {
    const params: any = {};

    if (any) params.any = any;

    return params;
  }
}
