export interface ITextTemplate {
  id: number;
  code: string;
  title: string;
  content: string;
  contentEn: string;
}

export interface ITextTemplateEditModel {
  code: string;
  title: string;
  content: string;
  contentEn: string;
}

export const textTemplateCodes = {
  home: {
    title: 'home_title',
    content: 'home_content'
  },
  contacts: {
    title: 'contacts_title',
    content: 'contacts_content'
  },
  studyPlan: {
    approvalEmailBody: 'study_plan_approval_email_body'
  }
};
