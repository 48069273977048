import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { permissions } from '@shared/models/Identity';
import { AppService } from '@shared/services/app.service';
import { TextTemplateService } from '@shared/services/text-template.service';
import { ITextTemplate, textTemplateCodes } from '@shared/models/TextTemplate';

let cache: {
  content: ITextTemplate,
  title: ITextTemplate
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  constructor(
    private app: AppService,
    private templates: TextTemplateService,
    private router: Router
  ) { }

  title: string;
  content: string;
  showPlanAlerts: boolean;

  ngOnInit() {
    if (!this.app.isAuthenticated) {
      this.router.navigate(['/study-programmes']);
      return;
    }

    this.app.setBreadcrumbs([]);

    this.showPlanAlerts = this.app.currentUser.hasPermission(permissions.studyPlanView);

    if (cache) {
      this.setPage(cache);
    } else {
      const loading = this.app.showLoading();
      forkJoin(
        this.templates.getByCode(textTemplateCodes.home.content),
        this.templates.getByCode(textTemplateCodes.home.title)
      ).subscribe(([content, title]) => {
        this.app.hideLoading(loading);

        cache = {
          content,
          title
        };

        this.setPage(cache);
      });
    }
  }

  onBodyClick(event) {
     const href = event.target.href;
     if (href && event.target.target !== '_blank') {
     	const attrHref = event.target.getAttribute('href');
     	if (attrHref[0] === '/') {
     		this.router.navigateByUrl(attrHref);
     		return false;
     	}
     }
  }

  private setPage({ title, content }: { title: ITextTemplate, content: ITextTemplate }) {
    this.title = this.getContent(title);
    this.content = this.getContent(content);
  }

  private getContent(template: ITextTemplate): string {
    return template ? this.app.currentLanguage == 'en' ? template.contentEn : template.content : undefined;
  }
}
