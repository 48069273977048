<mat-option disabled class="select-search">
  <div class="select-search-body">
    <input matInput
           #input
           name="search"
           [placeholder]="placeholder || ('common.search' | translate)"
           autocomplete="off"
           (keyup)="$event.stopPropagation(); onSearch(input.value)">
    <button mat-icon-button (click)="input.value = ''; onSearch(null)" disableRipple="true" *ngIf="input.value">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</mat-option>
<mat-option disabled *ngIf="isEmpty">
  {{'common.noItemsFound' | translate}}
</mat-option>
