import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-hyperlink-edit",
  templateUrl: "./edit.component.html",
})
export class HyperlinkEditComponent {
  constructor(
    private dialogRef: MatDialogRef<HyperlinkEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    data?: {
      value: string;
    }
  ) {
    this.initialValue = this.hyperlink = data && data.value;
  }

  hyperlink: string;

  readonly pattern = "^[hH][tT][tT][pP][sS]?://.+";
  readonly initialValue: string;

  /**
   * Close dialog returning result
   */
  continue() {
    this.dialogRef.close(this.hyperlink);
  }

  /**
   * Close dialog returning initial value
   */
  cancel() {
    this.dialogRef.close(this.initialValue);
  }
}
