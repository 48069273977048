import { Pipe, PipeTransform } from '@angular/core';

import { AppService } from '../services/app.service';

@Pipe({
  name: 'translateProp'
})
export class TranslatePropertyPipe implements PipeTransform {
  constructor(private app: AppService) { }

  transform(item: any, property: string = 'value'): any {
    if (!item) return '';

    const langProp = `${property}${this.app.currentLanguage}`.toLowerCase();
    let result = item[property];

    Object.keys(item).forEach((t) => {
      if (t.toLowerCase() === langProp) {
        result = item[t];
      }
    });

    return result;
  }
}
