import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'appDate' // "date" pipe is already reserved
})
export class AppDatePipe extends DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
    this.localeId = locale;
  }

  private localeId: string;

  transform(value: any, args?: any): any {
    let format: string;
    switch ((this.localeId || '').toLowerCase().split('-')[0]) {
      case 'lv':
      case 'en':
        format = 'dd.MM.yyyy.';
        break;
      // add other formats here
      default:
        format = 'yyyy-MM-dd';
    }

    if (args === true) {
      format += ' HH:mm:ss';
      args = undefined;
    }

    return super.transform(value, args || format);
  }
}
