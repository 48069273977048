import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IdleTimerFactory {
  create(options: { onTimeout: () => void; onStart: () => void; onStop: () => void }): IdleTimer {
    return new IdleTimer(options);
  }
}

export class IdleTimer {
  constructor(options: { onTimeout: () => void; onStart: () => void; onStop: () => void }) {
    this.onTimeout = options.onTimeout;
    this.onStart = options.onStart;
    this.onStop = options.onStop;

    this.setupEvents();
  }

  private readonly onTimeout: () => void;
  private readonly onStart: () => void;
  private readonly onStop: () => void;

  private timeoutMs: number;
  private active: boolean;
  private timeoutTracker;
  private updateTracker;
  private dispatchEvents: boolean = true;

  start(timeoutMs: number) {
    if (!timeoutMs) return;

    if (this.active) {
      console.warn('The idle timer is already started.');
      return;
    }

    console.log(`Start idle timer: ${timeoutMs}ms.`);

    this.timeoutMs = timeoutMs;
    this.active = true;

    this.dispatch('start');
    this.track();
    this.onStart();
  }

  reset() {
    this.stop();
    this.start(this.timeoutMs);
  }

  stop() {
    if (this.updateTracker) {
      clearTimeout(this.updateTracker);
    }

    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.active = false;

    this.onStop();
  }

  private setupEvents() {
    window.addEventListener('click', () => this.triggerUpdate());
    window.addEventListener('keydown', () => this.triggerUpdate());

    // listen to other tabs
    window.addEventListener('storage', (event) => {
      if (event.key === 'idle') {
        switch (event.newValue) {
          case 'timeout':
            this.disableEvents(() => this.triggerTimeout());
            break;

          case 'start':
            this.disableEvents(() => this.reset());
            break;

          case 'update':
            this.disableEvents(() => this.triggerUpdate());
            break;
        }
      }
    });
  }

  private triggerTimeout() {
    if (!this.active) return;

    this.dispatch('timeout');
    this.stop();
    this.onTimeout();
  }

  private triggerUpdate() {
    if (!this.active) return;

    this.dispatch('update');

    if (this.updateTracker) {
      clearTimeout(this.updateTracker);
    }

    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.updateTracker = setTimeout(() => {
      this.track();
    }, 500);
  }

  private track() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => {
      this.triggerTimeout();
    }, this.timeoutMs);
  }

  private dispatch(value: 'timeout' | 'start' | 'update') {
    if (!this.dispatchEvents) return;

    localStorage.setItem('idle', value);
    localStorage.removeItem('idle');
  }

  private disableEvents(fn: () => void) {
    this.dispatchEvents = false;
    fn();
    this.dispatchEvents = true;
  }
}
