import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit {
  @Input() placeholder: string;
  @Input() select: MatSelect;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  get isEmpty(): boolean {
    return !this.select.options.length;
  }

  @ViewChild('input', { static: true }) private input: ElementRef;

  ngOnInit() {
    this.select.openedChange.subscribe(() => {
      this.input.nativeElement.value = '';
      this.search.emit(null);
      this.input.nativeElement.focus();
    });
  }

  onSearch(value: string) {
    this.search.emit(value);
  }
}
