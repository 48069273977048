import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ITreeNode } from '../tree-ext/tree.model';
import { NodeDirective } from '../tree-ext/tree.component';

@Component({
  selector: 'app-tree-node-ext',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss'],
})
export class TreeNodeComponent<T> implements OnInit {
  @Input() node: ITreeNode<T>;
  @Input() expandableNodeTemplate: NodeDirective<T>;
  @Input() nodeTemplate: NodeDirective<T>;

  public get isExpandable(): boolean {
    return !!this.node.children.length
  }

  constructor() { }

  ngOnInit(): void {
  }

}
