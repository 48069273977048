import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { AppService } from '../shared/services/app.service';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private ngZone: NgZone) { }

  private _app: AppService;
  private get app(): AppService {
    if (!this._app) this._app = this.injector.get(AppService);

    return this._app;
  }

  private _auth: AuthService;
  private get auth(): AuthService {
    if (!this._auth) this._auth = this.injector.get(AuthService);

    return this._auth;
  }

  private _router: Router;
  private get router(): Router {
    if (!this._router) this._router = this.injector.get(Router);

    return this._router;
  }

  handleError(error) {
    console.error(error);

    let showText = true;
    let text = this.app.getHttpError(error);
    
    if (error instanceof HttpErrorResponse) {
      if (error.url.includes(this.auth.refreshUrl)) {
        showText = false;
        this.router.navigate(['/auth/login'], { queryParams: { error: true } });
      } else if (error.url.includes(this.auth.loginUrl) || error.url.includes(this.auth.logoutUrl)) {
        showText = false;
      } else {
        if (error.status === 401) {
          if (!this.auth.isAuthenticated) {
            showText = false;
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: '/' + this.router.url } });
          } else {
            text = this.app.translate('error.accessDenied');
          }
        }
      }
    } else if (!text) {
      text = this.app.translate(error.message || error);
    }

    this.ngZone.run(() => {
      this.app.hideLoading();

      if (showText) {
        this.app.alerts.error(text);
      }
    });
  }
}
