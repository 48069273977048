import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ITextTemplate, textTemplateCodes } from '@shared/models/TextTemplate';
import { AppService } from '@shared/services/app.service';
import { TextTemplateService } from '@shared/services/text-template.service';

let cache: {
  content: ITextTemplate,
  title: ITextTemplate
};

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {
  constructor(
    private app: AppService,
    private templates: TextTemplateService,
    private router: Router
  ) { }

  title: string;
  content: string;

  ngOnInit() {
    this.app.setBreadcrumbs([{
      label: this.app.translate('contacts.title')
    }]);

    if (cache) {
      this.setPage(cache);
    } else {
      const loading = this.app.showLoading();
      forkJoin(
        this.templates.getByCode(textTemplateCodes.contacts.content),
        this.templates.getByCode(textTemplateCodes.contacts.title)
      ).subscribe(([content, title]) => {
        this.app.hideLoading(loading);

        cache = {
          content,
          title
        };

        this.setPage(cache);
      });
    }
  }

  onBodyClick(event) {
    const href = event.target.href;
    if (href && event.target.target !== '_blank') {
      const attrHref = event.target.getAttribute('href');
      if (attrHref[0] === '/') {
        this.router.navigateByUrl(attrHref);
        return false;
      }
    }
  }

  private setPage({ title, content }: { title: ITextTemplate, content: ITextTemplate }) {
    this.title = this.getContent(title);
    this.content = this.getContent(content);
  }

  private getContent(template: ITextTemplate): string {
    return template ? this.app.currentLanguage == 'en' ? template.contentEn : template.content : undefined;
  }
}
