import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { AppService } from "../../services/app.service";

import { HyperlinkEditComponent } from "../hyperlink/edit.component";

import { Util } from "@core/Util";

export class IAttachment {
  id?: number;
  fileId?: string;
  file?: File;
  name?: string;
  url?: string;
  extension?: string;
  maxSizeError?: boolean;
  extensionError?: boolean;
  uploadStatus?: { percentDone?: number; error?: string };
  preview?: string;
}

export class Attachments {
  items: IAttachment[] = [];

  get files() {
    return this.items.filter((t) => t.fileId || t.file);
  }

  get links() {
    return this.items.filter((t) => t.url);
  }
}

export type AttachmentType = "file" | "document" | "link";

@Component({
  selector: "app-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AttachmentsComponent implements OnInit, AfterViewInit {
  constructor(private app: AppService, private dialog: MatDialog) {}

  /**
   * Supported attachment types. This affects both adding and removing an attachment.
   * If specific type is passed, then attachments of this type can be added and/or removed.
   * If specific type is omitted, then attachments of this type are still visible as read-only.
   */

  @Input() types: AttachmentType[] = ["file"];
  @Input() model = new Attachments();
  @Input() immediate: boolean;
  @Input() maxSize?: number;
  @Input() accept?: string;
  @Input() downloadFileFn: (file: IAttachment) => void;

  @Output() changed = new EventEmitter();

  filesEnabled: boolean;
  linksEnabled: boolean;
  singleType: boolean;

  readonly prettySize = Util.prettyFileSize;
  readonly error: string = ""; // fake error binding

  get hasError(): boolean {
    return this.model.items.some((t) => t.extensionError || t.maxSizeError);
  }

  @ViewChild("upload", { static: true })
  private uploadElement: ElementRef;

  ngOnInit() {
    this.filesEnabled = this.types.includes("file");
    this.linksEnabled = this.types.includes("link");
    this.singleType = this.types.length === 1;
  }

  ngAfterViewInit() {
    this.immediate && this.addFile();
  }

  /**
   * Initialize file explore for file selection
   */
  addFile() {
    this.uploadElement.nativeElement.click();
  }

  /**
   * On file inupt change
   * @param event HTMLElement change event
   */
  onFileSelect(event) {
    const files: File[] = [...event.target.files];

    files.forEach((n) => {
      const extension = n.name.substring(n.name.lastIndexOf(".") + 1);
      const preview = URL.createObjectURL(n);

      this.model.items.push({
        file: n,
        name: n.name,
        extension: "." + n.name.split(".").pop().toLowerCase(),
        maxSizeError: this.maxSize && n.size > this.maxSize,
        extensionError:
          this.accept && this.accept.indexOf(extension.toLowerCase()) === -1,
        preview: preview,
      });
    });

    this.changed.emit();
  }

  /**
   * Download file attachement
   * @param file Attachement file
   */
  downloadFile(file: IAttachment) {
    this.downloadFileFn(file);
  }

  /**
   * Open dialog for adding hyperlinks
   */
  addLink() {
    const dialog = this.dialog.open(HyperlinkEditComponent, {
      width: "800px",
    });

    dialog.afterClosed().subscribe((value) => {
      if (value) {
        this.model.items.push({ url: value });
        this.changed.emit();
      }
    });
  }

  /**
   * Open dialog for editing hyperlinks
   */
  editLink(link: IAttachment) {
    const dialog = this.dialog.open(HyperlinkEditComponent, {
      data: {
        value: link.url,
      },
      width: "800px",
    });

    dialog.afterClosed().subscribe((value) => {
      // drop the reference to existing object to trigger remove/add since there is no attachment update method
      link.id = undefined;
      link.url = value;
    });
  }

  /**
   * On file image preview error
   * @param file Attachement file
   */
  onPreviewError(file: IAttachment) {
    file.preview = null;
  }

  /**
   * Remove file
   * @param item Attachement file
   */
  remove(item: IAttachment) {
    Util.removeItemFromArray(this.model.items, item);
    this.changed.emit();
  }
}
