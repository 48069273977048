import { OnDestroy, Directive, ViewContainerRef, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AppService } from '@shared/services/app.service';

@Directive({
  selector: '[appMaxLengthError]'
})
export class MaxLengthErrorDirective implements OnDestroy {
  constructor(
    private viewContainer: ViewContainerRef,
    private app: AppService
  ) {
    this.text = this.app.translate('error.maxLengthExceeded');
  }

  private readonly text: string;
  private subscription: Subscription;

  @Input() set appMaxLengthError(control: AbstractControl) {
    this.subscription = control.statusChanges.subscribe(() => {
      const error = control?.errors?.maxlength;

      if (error) {
          this.clear();
          this.viewContainer.element.nativeElement.innerText = this.text;
      } else {
        this.clear();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private clear() {
    this.viewContainer.clear();
    this.viewContainer.element.nativeElement.innerText = '';
  }
}
