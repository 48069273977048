import { AppService } from '@shared/services/app.service';
import { StudyPlanService } from '@shared/services/study-plan.service';
import { IStudyPlanAlertItem, StudyPlanCourseAlertLevel } from '@shared/models/StudyPlan';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-study-plan-alerts',
  templateUrl: './study-plan-alerts.component.html',
  styleUrls: ['./study-plan-alerts.component.scss']
})
export class StudyPlanAlertsComponent implements OnInit {
  constructor(
    private app: AppService,
    private service: StudyPlanService
  ) { }

  readonly columnsToShow = [
    'studyProgramme',
    'implementedProgramme',
    'period',
    'version'
  ];

  table: MatTableDataSource<IStudyPlanAlertItem>;
  hasItems: boolean;

  ngOnInit() {
    const loading = this.app.showLoading();

    this.service.getAlerts([StudyPlanCourseAlertLevel.CourseChangeCritical, StudyPlanCourseAlertLevel.CourseChangeFundamental]).subscribe(data => {
      this.app.hideLoading(loading);

      this.hasItems = data.length > 0;
      this.table = new MatTableDataSource(data);
    });
  }

  getAffectedPeriodsDisplay(item: IStudyPlanAlertItem): string {
    return item.affectedPeriods.map(t => this.app.translateProperty(t, 'name')).join('\n');
  }
}
