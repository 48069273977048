import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Util } from "@core/Util";
import { Patch } from "@shared/models/common";
import {
  ICourseAddModel,
  ICourseEditModel,
  IStudyPlanAddVersionModel,
  IStudyPlanCopyVersionModel,
  IStudyPlanCourse,
  IStudyPlanCreateModel,
  IStudyPlanPeriod,
  IStudyPlanVersion,
  IPeriodEditModel,
  IStudyPlan,
  IStudyPlanListItem,
  IStudyPlanVersionSearchResult,
  IStudyPlanVersionCompare,
  IStudyPlanPendingApproval,
  IStudyPlanFinishedApproval,
  IStudyPlanCopyVersionResult,
  IStudyPlanUpdateVersionModel,
  IStudyPlanAlertItem,
  ICoursePlanning,
  IStudyPlanFinishResult,
  StudyPlanCourseAlertLevel,
} from "@shared/models/StudyPlan";

import { environment } from "src/environments/environment";

interface IGetCriteria {
  periodId?: number;
  flowId?: string;
  isStandard?: boolean;
}

interface IFindVersionCriteria {
  id?: number[];
  implementedProgrammeId?: number[];
  isStandard?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class StudyPlanService {
  constructor(private http: HttpClient) { }

  get apiUrl(): string {
    return `${environment.apiUrl}/studyPlans`;
  }

  /**
   * Get study plan
   * @param implementedProgrammeId Implemented programme id
   * @param criteria Optional: Criterias for additional filtration
   * @returns Http request observable returns study plan list item array
   */
  get(
    implementedProgrammeId: number,
    criteria?: IGetCriteria
  ): Observable<IStudyPlanListItem[]> {
    const params: any = { implementedProgrammeId };

    if (criteria) {
      Object.keys(criteria).forEach((t) => {
        params[t] = criteria[t];
      });
    }

    return this.http.get<IStudyPlanListItem[]>(this.apiUrl, {
      params,
    });
  }

  /**
   * Get study plan by id
   * @param id Study plan id
   * @returns Http request observable returns study plan
   */
  getById(id: number): Observable<IStudyPlan> {
    return this.http.get<IStudyPlan>(`${this.apiUrl}/${id}`);
  }

  /**
   * Get study plan versions by study plan id
   * @param id Study plan id
   * @returns Http request observable returns study plan version array
   */
  getVersionsById(id: number): Observable<IStudyPlanVersion[]> {
    return this.http.get<IStudyPlanVersion[]>(`${this.apiUrl}/${id}/versions`);
  }

  /**
   * Create new study plan
   * @param item Study plan create model
   * @returns Http request observable returns study plan id
   */
  create(item: IStudyPlanCreateModel): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}`, item);
  }

  /**
   * Delete study plan
   * @param id Study plan id
   * @returns Http request observable
   */
  delete(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete(url);
  }

  /**
   * Export study plan.
   * @param id Study plan id
   * @param periodIds Study plan period IDs to include
   * @param isEnglish Should export using document template english file
   */
  export(id: number, periodIds: number[], isEnglish: boolean = false): Observable<any> {
    const url = `${this.apiUrl}/export/${id}?isEnglish=${isEnglish}`;

    return Util.downloadFile(url, this.http, periodIds);
  }

  /**
   * Create study plan version
   * @param id Study plan id
   * @param item Study plan add version model
   * @returns Http request observable returns study plan version id
   */
  createVersion(
    id: number,
    item: IStudyPlanAddVersionModel
  ): Observable<number> {
    return this.http.post<any>(`${this.apiUrl}/${id}/versions`, item);
  }

  /**
   * Update study plan version
   * @param versionId Study plan version id
   * @param item Study plan update version model
   * @returns Http request observable
   */
  updateVersion(
    versionId: number,
    item: IStudyPlanUpdateVersionModel
  ): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/versions/${versionId}`, item);
  }

  /**
   * Set study plan version accreditation state
   * @param versionId Study plan version id
   * @param isAccredited Is accredited
   * @returns Http request observable
   */
  setAccreditation(versionId: number, isAccredited: boolean): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/versions/${versionId}/accreditation`,
      isAccredited
    );
  }

  /**
   * Copy study plan version
   * @param versionId Study plan version id
   * @param item Study plan copy version model
   * @returns Http request observable returns study plan copy version result
   */
  copyVersion(
    versionId: number,
    item: IStudyPlanCopyVersionModel
  ): Observable<IStudyPlanCopyVersionResult> {
    return this.http.post<any>(
      `${this.apiUrl}/versions/${versionId}/copy`,
      item
    );
  }

  /**
   * Delete study plan version
   * @param versionId Study plan version id
   * @returns Http request observable
   */
  deleteVersion(versionId: number): Observable<any> {
    const url = `${this.apiUrl}/versions/${versionId}`;
    return this.http.delete(url);
  }

  /**
   * Add study course to study plan version
   * @param versionId Study plan version id
   * @param item Course add model
   * @returns Http request observable
   */
  addCourse(versionId: number, item: ICourseAddModel): Observable<number[]> {
    return this.http.post<number[]>(
      `${this.apiUrl}/versions/${versionId}/courses`,
      item
    );
  }

  /**
   * Update study plan course
   * @param studyPlanCourseId Study plan course id
   * @param item Course edit model
   * @returns Http request observable
   */
  updateCourse(
    studyPlanCourseId: number,
    item: ICourseEditModel
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/courses/${studyPlanCourseId}`,
      item
    );
  }

  /**
   * Patch study plan course
   * @param studyPlanCourseId Study plan course id
   * @param data Course edit model patch
   * @returns Http request observable
   */
  patchCourse(
    studyPlanCourseId: number,
    data: Patch<ICourseEditModel>
  ): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/courses/${studyPlanCourseId}`,
      Util.createPatchOperations(data)
    );
  }

  /**
   * Remove study plan course
   * @param studyPlanCourseId Study plan course id
   * @param allPeriods Optional: All periods
   * @param discontinue Optional: Discontinue
   * @returns Http request observable
   */
  removeCourse(
    studyPlanCourseId: number,
    allPeriods?: boolean,
    discontinue?: boolean
  ): Observable<any> {
    const url = `${this.apiUrl}/courses/${studyPlanCourseId}`;
    const params: any = {};

    if (allPeriods !== undefined) params.allPeriods = allPeriods;
    if (discontinue !== undefined) params.discontinue = discontinue;

    return this.http.delete(url, { params });
  }

  /**
   * Replace study plan course
   * @param studyPlanCourseId Study plan course id
   * @param item Course add model
   * @returns Http request observable returns replaced study plan course id array
   */
  replaceCourse(
    studyPlanCourseId: number,
    item: ICourseAddModel
  ): Observable<number[]> {
    return this.http.post<number[]>(
      `${this.apiUrl}/courses/${studyPlanCourseId}/replace`,
      item
    );
  }

  /**
   * Actualize study plan course
   * @param studyPlanCourseId Study plan course id
   * @returns Http request observable returns actualized version number
   */
  actualizeCourse(studyPlanCourseId: number): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/courses/${studyPlanCourseId}/actualize`,
      null
    );
  }

  /**
   * Refresh courses by version id
   * @param versionId Study plan version id
   * @returns Http request observable
   */
  refreshCourses(versionId: number): Observable<any> {
    return this.http.post<number>(
      `${this.apiUrl}/versions/${versionId}/courses/refresh`,
      null
    );
  }

  /**
   * Update study plan period
   * @param studyPlanPeriodId Study plan period id
   * @param item Period edit model
   * @returns Http request observable
   */
  updatePeriod(
    studyPlanPeriodId: number,
    item: IPeriodEditModel
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/periods/${studyPlanPeriodId}`,
      item
    );
  }

  /**
   * Patch study plan period
   * @param studyPlanPeriodId Study plan period id
   * @param data Period edit model patch
   * @returns Http request observable
   */
  patchPeriod(
    studyPlanPeriodId: number,
    data: Patch<IPeriodEditModel>
  ): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/periods/${studyPlanPeriodId}`,
      Util.createPatchOperations(data)
    );
  }

  /**
   * Get courses by version id
   * @param versionId Study plan version id
   * @returns Http request observable returns study plan course array
   */
  getCourses(versionId: number): Observable<IStudyPlanCourse[]> {
    return this.http.get<any>(`${this.apiUrl}/versions/${versionId}/courses`);
  }

  /**
   * Get periods by version id or (version id and study plan course code)
   * @param versionId Study plan version id
   * @param courseCode Optional: Course code
   * @returns Http request observable returns study plan semester array
   */
  getPeriods(
    versionId: number,
    courseCode?: string
  ): Observable<IStudyPlanPeriod[]> {
    const params: any = {};

    if (courseCode) params.courseCode = courseCode;

    return this.http.get<IStudyPlanPeriod[]>(
      `${this.apiUrl}/versions/${versionId}/periods`,
      { params }
    );
  }

  /**
   * Get course planning
   * @param courseId Course id
   * @param studyProgrammeId Study programme id
   * @param implementedProgrammeId Implemented programme id
   * @returns Http request observable returns course planning array
   */
  getPlannings(
    courseId: number,
    studyProgrammeId?: number,
    implementedProgrammeId?: number
  ): Observable<ICoursePlanning[]> {
    const params: any = { courseId };

    if (studyProgrammeId) params.studyProgrammeId = studyProgrammeId;
    if (implementedProgrammeId)
      params.implementedProgrammeId = implementedProgrammeId;

    return this.http.get<ICoursePlanning[]>(`${this.apiUrl}/plannings`, {
      params,
    });
  }

  /**
   * Find study plan versions
   * @param criteria Find version criteria
   * @returns Http request observable returns study plan version search result array
   */
  findVersions(
    criteria: IFindVersionCriteria
  ): Observable<IStudyPlanVersionSearchResult[]> {
    const params: any = {};

    if (criteria) {
      Object.keys(criteria).forEach((k) => {
        if (criteria[k]) params[k] = criteria[k];
      });
    }

    return this.http.get<IStudyPlanVersionSearchResult[]>(
      `${this.apiUrl}/versions/find`,
      { params }
    );
  }

  /**
   * Compare study plan versions
   * @param versionIds Study plan version id array
   * @returns Http request observable returns study plan version compare array
   */
  compareVersions(
    versionIds: number[]
  ): Observable<IStudyPlanVersionCompare[]> {
    const params: any = { versionId: versionIds };
    return this.http.get<IStudyPlanVersionCompare[]>(
      `${this.apiUrl}/versions/compare`,
      { params }
    );
  }

  /**
   * Get study plans containing alerts.
   * @param level List of alert levels to include
   * @returns Http request observable returns study plan alert item array
   */
  getAlerts(level: StudyPlanCourseAlertLevel[]): Observable<IStudyPlanAlertItem[]> {
    const url = `${this.apiUrl}/alerts`;
    return this.http.get<IStudyPlanAlertItem[]>(url, {
      params: {
        level: level.join(',')
      }
    });
  }

  /**
   * Finish old study plans.
   * @returns Number of updated plan versions and the list of programmes containing unifinished plans.
   */
  finish(): Observable<IStudyPlanFinishResult> {
    const url = `${this.apiUrl}/finish`;
    return this.http.post<IStudyPlanFinishResult>(url, null);
  }

  /**
   * Start study plan approval
   * @param id Study plan id
   * @returns Http request observable
   */
  startApproval(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}/approval/start`;
    return this.http.post(url, null);
  }

  /**
   * Cancel study plan approval
   * @param id Study plan id
   * @returns Http request observable
   */
  cancelApproval(id: number): Observable<any> {
    const url = `${this.apiUrl}/${id}/approval/cancel`;
    return this.http.post(url, null);
  }

  /**
   * Send study plans for approval
   * @param ids Study plan id array
   * @param message Message
   * @returns Http request observable
   */
  sendForApproval(ids: number[], message: string): Observable<any> {
    const url = `${this.apiUrl}/approval/send`;
    return this.http.post(url, { ids, message });
  }

  /**
   * Notify approval initiators
   * @param ids Study plan id array
   * @param message Message
   * @returns Http request observable
   */
  notifyApprovalInitiators(ids: number[], message: string): Observable<any> {
    const url = `${this.apiUrl}/approval/notify`;
    return this.http.post(url, { ids, message });
  }

  /**
   * Approve study plan
   * @param id Study plan id
   * @param comment Optional: Comment
   * @returns Http request observable
   */
  approve(id: number, comment?: string): Observable<any> {
    const url = `${this.apiUrl}/${id}/approval/approve`;
    return this.http.post(url, { comment });
  }

  /**
   * Reject study plan
   * @param id Study plan id
   * @param comment Comment
   * @param withChanges Optional: Reject with changes
   * @returns Http request observable
   */
  reject(id: number, comment: string, withChanges?: boolean): Observable<any> {
    const url = `${this.apiUrl}/${id}/approval/reject`;
    return this.http.post(url, { comment, withChanges });
  }

  /**
   * Accpet study plan changes
   * @param id Study plan id
   * @param comment Optional: Comment
   * @returns Http request observable
   */
  acceptChanges(id: number, comment?: string): Observable<any> {
    const url = `${this.apiUrl}/${id}/approval/acceptChanges`;
    return this.http.post(url, { comment });
  }

  /**
   * Get study plan array with started approval status
   * @param studyProgrammeId Study programme id
   * @returns Http request observable returns study plan started approval array
   */
  getStartedApprovals(
    studyProgrammeId: number
  ): Observable<IStudyPlanPendingApproval[]> {
    const url = `${this.apiUrl}/approval/started`;
    return this.http.get<IStudyPlanPendingApproval[]>(url, {
      params: { studyProgrammeId },
    });
  }

  /**
   * Get study plan array with pending approval status
   * @param studyProgrammeId Study programme id
   * @returns Http request observable returns study plan pending approval array
   */
  getPendingApprovals(
    studyProgrammeId: number
  ): Observable<IStudyPlanPendingApproval[]> {
    const url = `${this.apiUrl}/approval/pending`;
    return this.http.get<IStudyPlanPendingApproval[]>(url, {
      params: { studyProgrammeId },
    });
  }

  /**
   * Get study plan array with finished approval status
   * @param studyProgrammeId Study programme id
   * @returns Http request observable returns study plan finished approval array
   */
  getFinishedApprovals(
    studyProgrammeId: number
  ): Observable<IStudyPlanFinishedApproval[]> {
    const url = `${this.apiUrl}/approval/finished`;
    return this.http.get<IStudyPlanFinishedApproval[]>(url, {
      params: { studyProgrammeId },
    });
  }
}
