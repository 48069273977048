import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AppService } from '@shared/services/app.service';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(
    private app: AppService,
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.isAuthenticated) {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      return;
    }

    const user = this.auth.currentUser;

    if (user.isAdmin) return true;

    let hasAccess = false;

    const customFn = route.data['canActivate'];

    if (customFn) {
      hasAccess = customFn(user);
    } else {
      const roles = <Array<string>>(route.data['roles'] || []);
      const rights = <Array<string>>(route.data['rights'] || []);

      hasAccess =
        (!roles.length || roles.some((r) => user.hasRole(r))) &&
        (!rights.length || rights.some((r) => user.hasPermission(r)));
    }

    if (!hasAccess) {
      this.app.alerts.error(this.app.translate('error.accessDenied'));
    }

    return hasAccess;
  }
}
