import { Component, Inject, Input } from "@angular/core";

import { AppService } from "@shared/services/app.service";
import { EmployeeService } from "@shared/services/employee.service";
import { WindowToken } from "@core/WindowToken";

/**
 * Employee profile link component
 */
@Component({
  selector: 'app-employee-link',
  templateUrl: './employee-link.component.html'
})
export class EmployeeLinkComponent {
  constructor(
    private app: AppService,
    private service: EmployeeService,
    @Inject(WindowToken) private window: Window
  ) {}

  @Input() id: string;
  @Input() name: string;
  @Input() type: 'employee' | 'department' = 'employee';

  open() {
    const tab = window.open('', '_blank');

    const request = this.type == 'department'
      ? this.service.getDepartmentUrl(this.id)
      : this.service.getProfileUrl(this.id);

    request.subscribe(url => {
      if (url) {
        tab.location.href = url;
      } else {
        tab.close();
        this.app.alerts.error(this.app.translate('error.employeeProfileUrlEmpty'));
      }
    });
  }
}
