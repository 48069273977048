export class Cache {
  private data: { [key: string]: any } = {};

  setValue(key: string, data: any) {
    this.data[key] = data;
  }

  getValue(key: string) {
    return this.data[key];
  }

  remove(key: string) {
    delete this.data[key];
  }
}

export const cache = new Cache();

export const cacheKeys = {
  implementedProgrammeFilterOptions: 'implementedProgrammeFilterOptions',
  implementedProgrammeFilterValues: 'implementedProgrammeFilterValues',
  implementedProgrammeSearch: 'implementedProgrammeSearch',
  studyProgrammeFilterOptions: 'studyProgrammeFilterOptions',
  studyProgrammeFilterValues: 'studyProgrammeFilterValues',
  studyProgrammeSearch: 'studyProgrammeSearch'
};
