export interface IParameter {
  id: number;
  code: string;
  value: string;
}

export const parameterCodes = {
  appTitle: 'app_title',
  appUrl: 'app_url',
  defaultRole: 'default_role',
  documentTemplateMaxSize: 'doc_template_max_size',
  documentTemplateExtensions: 'doc_template_extensions',
  idleTimeout: 'idle_timeout_seconds',
  pageSize: 'page_size',
  creditPointToEctsCoefficient: 'credit_point_to_ects_coef'
};
